(function (w, d, $) {
    var APP_URL = $('#app_url').val();
    var flag = true;

    $('.open-pin').bind("click touchstart", function () {
        var id = $(this).data('id');
        var id_forms_to_patient = $(this).data('idformstopatient');

        $('body').find('.tablet-pin-wrapper').hide();
        $('body').find('.tablet-patient-wrap').hide();

        $('body').find('#tablet-pin-wrapper-'+id).show(); 
        $('body').find('#tablet-patient-wrap-'+id).show();

        $('body').find('.id_forms_to_patient-'+id).val(id_forms_to_patient);
    });


    $('.submit-tablet-pincode').bind("click", function () {
        var id_patient = $(this).data('idpatient');
        var id_forms_to_patient = $('body').find('.id_forms_to_patient-'+id_patient).val();
        var next = $(this).data('next');

        if (flag == true) {
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL + 'forms/tabletLoginPin',
                data: {
                    id_patient: id_patient,
                    id_forms_to_patient: id_forms_to_patient,
                    next: next,
                    pin_code: $('.tablet-pincode-'+id_patient).val(),
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    if (data.success == true) {
                        window.location = data.redirect;
                        return;
                    } else {
                        $('#server-message-pin-code-'+id_patient).html(data.html).show();
                    }
                }
            });
            flag = true;
        }
    });

    $('.staffLogin').bind("click touchstart", function () {
        $('body').find('#staffLogin').show();
        $('body').find('.staffLoginWrap').hide();
    });




    $('body').on('click', '.check_all_y', function () {
        $(".all_y").prop("checked", true);
        $(".all_n").prop("checked", false);
        $(".all_u").prop("checked", false);
    })

    $('body').on('click', '.check_all_n', function () {
        $(".all_n").prop("checked", true);
        $(".all_y").prop("checked", false);
        $(".all_u").prop("checked", false);
    })

    $('body').on('click', '.check_all_u', function () {
        $(".all_u").prop("checked", true);
        $(".all_y").prop("checked", false);
        $(".all_n").prop("checked", false);
    })

    $('body').on('click', '.all_y,.all_n,.all_u', function () {
        $(".check_all_y").prop("checked", false);
        $(".check_all_n").prop("checked", false);
        $(".check_all_u").prop("checked", false);
    })




    $('body').on('click', '.q19_all_yes', function () {
        $(".q19_yes").prop("checked", true);
        $(".q19_no").prop("checked", false);
    })

    $('body').on('click', '.q19_all_no', function () {
        $(".q19_no").prop("checked", true);
        $(".q19_yes").prop("checked", false);
    })

    $('body').on('click', '.q19_no,.q19_yes', function () {
        $(".q19_all_no").prop("checked", false);
        $(".q19_all_yes").prop("checked", false);
    })

    $('.submit-staff-login').bind("click touchstart", function () {
        var pin_code = $('body').find('.staff-login-tablet-pincode').val();

        if (flag == true) {
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL + 'forms/tabletStaffLoginPin',
                data: {
                    pin_code: pin_code,
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    if (data.success == true) {
                        window.location = data.redirect;
                        return;
                    } else {
                        $('#server-message-staff-login').html(data.html).show();
                    }
                }
            });
            flag = true;
        }
    });


    $(".submit-form-modal").click(function() {
        $("#open-forms-wrap").animate({ scrollTop: 0 }, "slow");
    });
})(window, document, jQuery);