(function(w,d,$){
    var APP_URL = $('#app_url').val();
    var patient_id_global = null;

    //Format the phone and fax inputs
    $('body').find('.phone-input-format')
        .mask('(000)000-0000');

    $('body').find('.phone-input-format-no')
        .mask('0000000000');
        
    var file_id_register;
    var flag = true;
    var flagCollapse = true;
    var interval = [];
    db_calendar_slots = []
    /*Datepicker Setup*/
    $('.patient_datepicker').datepicker({
        format: "mm-dd-yyyy",
        autoclose: true 
    }); 
    $('#date_start').datepicker({
        format: "mm-dd-yyyy",
        autoclose: true 
    });
    $('#date_end').datepicker({
        format: "mm-dd-yyyy",
        autoclose: true  
    });
    $('.input_date').datepicker({
        format: "mm-dd-yyyy",
        autoclose: true 
    });

    $('#create_account_form').submit(function(e) {
        $( "#create_account_submit" ).prop( "disabled", true );
    });

    $('.form-horizontal').submit( function(e) {
        $('button[type="submit"]').attr('disabled',true);
    });
    /*SMS Reset Code Submit*/
    $('#phone_reset_code').submit(function(e) { 
        // this code prevents form from actually being submitted
        if(flag==true){
            flag = false;
            e.preventDefault();
            e.returnValue = false;

            var $form = $(this);
            var dob_modal = $('#dob_modal').val();
            // this is the important part. you want to submit
            // the form but only after the ajax call is completed
            $.ajax({ 
                type: 'post',
                url: APP_URL+'checkPhoneRecords',
                data: {
                    phone: $('#phone').val(),
                    dob: dob_modal,
                    _token: $('[name="_token"]').val()
                },
                context: $form, // context will be "this" in your handlers
                success: function(data) { // your success handler
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    flag = true;
                    if(data.success==false){
                        $('#server-message').html('').html(data.html);
                        return false;
                    }else{
                        var count = data.count;
                        if(count <=1){
                            // make sure that you are no longer handling the submit event; clear handler
                            this.off('submit');
                            // actually submit the form
                            this.submit();
                        }else{
                            $('#dob_modal').val('');
                            $('#dobModal').modal('toggle');
                        }
                    }
                },
                error: function() { // your error handler
                },
                complete: function() { 
                }
            });
        }
    });
    /*END SMS Reset Code Submit*/
    $('body').on('click','.set_dob',function(e){
        var dob_modal = $('#dob_modal').val();
        if(dob_modal=="")
            alert("DOB is required!");
        $('#dob').val(dob_modal);

        $('#phone_reset_code').submit();
    });


    /*Change Location*/
    $('body').on('click','.menu_location_select',function() {
        
        var id =  $(this).data('id');
        var formType = $(this).data('formtype');

        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    id: id,
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+formType+'changeLocation',
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }
                    location.reload();
                }
            });
        }
    });
    /********************/

    /*Disable Submit Additional Button*/
/*    $('#saveAdditionalForms').submit(function() {
        $(this).find(".saveAdditionalForms").prop('disabled',true);
    });*/

    $('body').on('click','.saveAdditionalForms',function(e) {
        $('body').find(".saveAdditionalForms").prop('disabled',true);
        var url = $('#saveAdditionalForms').attr('action');
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: url,
                data: $("#saveAdditionalForms").serialize(),
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }
                    
                    if(data.success==false){
                        $('#server-message').html('').html(data.html);
                        $('body').find(".saveAdditionalForms").prop('disabled',false);
                        $('body').animate({ scrollTop: 0 }, 'slow');
                    }else{
                        window.location = data.redirect;
                    }
                    flag = true;
                }
            });
        }
        e.preventDefault();
    });
    /*End*/
    /*End Datepicker Setup*/
    $('#patients_list .accordian-body').on('show.bs.collapse', function (e) {
        if(flagCollapse==true){

            var targetClass = '';
            if(typeof(e) != "undefined"){
                targetClass = e.target.className.split(" ")[0];
            }
            var patientid = $(this).data('patientid');
            patient_id_global = patientid;
            flagCollapse = false;
            $.ajax({
                type: "POST",
                data: {
                    patientid: patientid,
                    target_class: targetClass,
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'getPatientDataList',
                success: function(data)
                {   
                    flagCollapse = true;
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }
                    $('.emptyContentVPW').html('');
                    $('#visit_purpose_wrap'+patientid).html('').html(data.html);

                    var file_tab_click = $('#file_tab_click').val()
                    $('body').find(".tab_"+file_tab_click).click()
                    setTimeout( function (){
                        $('body').find("#tab_"+file_tab_click).click()
                    }
                    , 1500);
                }
            });
        }
    })

    $('body').on('click', '.notes_list', function() {
        if(flagCollapse==true){

            var targetClass = 'notes_list';
            var patientid = $(this).data('patientid');
            patient_id_global = patientid;
            flagCollapse = false;
            $.ajax({
                type: "POST",
                data: {
                    patientid: patientid,
                    target_class: targetClass,
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'getPatientDataList',
                success: function(data)
                {   
                    flagCollapse = true;
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }
                    $('.emptyContentVPW').html('');
                    $('#visit_purpose_wrap'+patientid).html('').html(data.html);
                }
            });
        }
    })

    /**
    * Retrieve patient files from webiz
    */ 
    $('body').on('click', '.close-webiz-container', function() {
        $('#patientDataFromWebIZContainer').hide();
        $('#getPatientDataFromWebIZ').show();
    })

    $('body').on('click', '#getPatientDataFromWebIZNeedsAttention', function () {

        $('#patientDataFromWebIZContainer').html('<a target="_blank" style="margin-top: 40px;" class="btn btn-sm btn-default">Loading. Please Wait ...</a>');
        $('#getPatientDataFromWebIZ').hide();
        $('#patientDataFromWebIZContainer').show();
        var rid = $(this).data('rid')
        var _d = {
            patientid: $(this).data('patientid'),
            consentid: $(this).data('consentid'),
            internal_id: 0,
            makePDF: $(this).data('makepdf'),
            _token: $('[name="_token"]').val()
        };
        $.ajax({
            type: "GET",
            data: _d,
            url: APP_URL + $(this).data('route'),
            success: function (data, status, xhr) {
                location.reload();
            },
            error: function (data, status, xhr) {
                console.log(data.responseJSON.error)
                $('.needs_attention_button_error_'+rid).html(data.responseJSON.error)
            }
        });
    }) 

    $('body').on('click', '#getPatientDataFromWebIZ', function(){
        
        $('#patientDataFromWebIZContainer').html('<a target="_blank" style="margin-top: 40px;" class="btn btn-sm btn-default">Loading. Please Wait ...</a>');
        $('#getPatientDataFromWebIZ').hide();
        $('#patientDataFromWebIZContainer').show();
        var _d = {
            patientid: patient_id_global,
            consentid: $(this).data('consentid'),
            internal_id: 0,
            makePDF: $(this).data('makepdf'),
            _token: $('[name="_token"]').val()
        };
        $.ajax({
            type: "GET",
            data: _d,
            url: APP_URL+$(this).data('route'),
            success: function(data, status, xhr)
            {
                var tpl = '<tr><td>{{age_at_adm}}</td><td>{{clinic}}</td><td>{{vaccine}}</td><td>{{vaccine_date}}</td></tr>';
                var d;
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                if(typeof(data.multiple_patients) != "undefined" && data.multiple_patients==true){
                    $('#patientDataFromWebIZContainer').html(data.data);
                    return;
                }

                if(data.data.rxa && data.data.rxa.length > 0) {
                    $('#buttonGeneratePdfWebIZ').attr('disabled',false);

                    d = data.data.rxa;
                    $('#patientDataFromWebIZContainer').html(function(){
                        var out = [];


                        out.push('<div style="padding-top: 45px;"><button type="button" class="close close-webiz-container" aria-label="Close"><span style="color:black;font-size=20px;" aria-hidden="true">&times;</span></button></div><thead><tr><th>Vaccine</th><th>Vaccine Date</th><th>Clinic</th><th>Age at adm</th></tr></thead>');
                        d.forEach(function(v,i){
                            var row = [];
                            var keys = Object.keys(v);
                            keys.forEach(function(k){
                                if(k == 'cvx') {
                                    return;
                                }
                                row.push('<td>' + v[k] + '</td>');
                            });
                            out.push('<tr>' + row.join('\n\t') + '</tr>');
                        });
                        return '<table class="table">' + out.join('\n\t') + '</table>';
                    });

                    /* vaccine not present */
                    data.data.idsNotPresent.forEach(function (v, i) {
                        $('body').find('#vaccine_not_present_'+v).html(data.data.message);
                        $('body').find('#'+v).css('height', '125px');
                    });
                    /* ***** */

                    $('body').find('.row_collapse_'+patient_id_global).click();
                    setTimeout( function (){
                        $('body').find('.row_collapse_'+patient_id_global).click();
                    }
                    , 750);
                } else {
                    $('#patientDataFromWebIZContainer').html('<a target="_blank" style="margin-top: 10px;" class="btn btn-sm btn-danger">Data not found</a>');
                }
            },
            error: function(data, status, xhr)
            {
                $('#patientDataFromWebIZContainer').html('<a target="_blank" style="margin-top: 10px;" class="btn btn-sm btn-danger">' + data.responseJSON.error + '</a>');
            }
        });
    }) 

    ///////////////////////////////////
    $('body').on('click', '.multiple_select_patient', function(){
        var internal_id = $(this).data('patientkey');
        $.ajax({
            type: "GET",
            data: {
                patientid: patient_id_global,
                internal_id: internal_id,
                makePDF: 3,
                _token: $('[name="_token"]').val()
            },
            url: APP_URL+$(this).data('route'),
            success: function(data)
            {   
                flagCollapse = true;
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }
                $('body').find('.row_collapse_'+patient_id_global).click();
                $('#patientDataFromWebIZContainer').html('');
                $('body').find('#getPatientDataFromWebIZ').show();
                setTimeout( function (){
                    $('body').find('.row_collapse_'+patient_id_global).click();
                }
                , 750);
            }
        });
    })

    $('body').on('click','#buttonGeneratePdfWebIZ',function (e){
        e.preventDefault();
        var url = $('#buttonGeneratePdfWebIZ').val();
        var win = window.open(url, '_blank');
        win.focus();
    });

    $('#patients_list .accordian-body').on('show.bs.collapse hide.bs.collapse', function () {
        var target = $(this).data('target');
        $("td[data-target='"+target+"'] .table-bullet-wrap .glyphicon ").toggleClass( 'glyphicon-triangle-right glyphicon-triangle-bottom' );
        $(this).closest("table")
            .find(".collapse.in")
            .not(this)
            .collapse('toggle')

        //location.hash = target;
    })
    /*END Patients List Arrow Toogle*/
    /*Referral select dropdown*/
    $( "#referral_source" ).change(function() {
        var referralValue = $( "#referral_source" ).val();
        $('#online,#google,#physician,#online,#school,#work,#other,#company,#attorney,#pharmacy,#urgent_care').hide();
        $('.referral_response').val('');
        /*
        3 = online
        4 = google
        5 = physician
        3 = online
        6 = school
        8 = work
        9 = other
        10 = company
        11 = attorney
        12 = pharmacy
        13 = urgent_care
        */ 
        if(referralValue == '3'){
            $('#online').show();
        }else if(referralValue == '4'){
            $('#google').show();
        }else if(referralValue == '5'){
            $('#physician').show();
        }else if(referralValue == '3'){
            $('#online').show();
        }else if(referralValue == '6'){
            $('#school').show();
        }else if(referralValue == '8'){
            $('#work').show();
        }else if(referralValue == '9'){
            $('#other').show();
        }else if(referralValue == '10'){
            $('#company').show();
        }else if(referralValue == '11'){
            $('#attorney').show();
        }else if(referralValue == '12'){
            $('#pharmacy').show();
        }else if(referralValue == '13'){
            $('#urgent_care').show();
        }else{
            $('#online,#google,#physician,#online,#school,#work,#other,#company,#attorney,#pharmacy,#urgent_care').hide();
        }
    });
    /*END Referral select dropdown*/
    /*Travel select dropdown*/
    $('body').on('change', '#visit_purpose', function() {
        var purpose = $( "#visit_purpose" ).val();
        /* 3 = travel */
        $('body').find('#travel-section-desktop').hide();
        if(purpose == '3'){
            $('body').find('#travel-section-desktop').show();
        }else{
            $('body').find('#travel-section-desktop').hide();
        }

        /* 2 = school */
        if(purpose == '2'){
            $('body').find('#school-section-desktop').show();
        }else{
            $('body').find('#school-section-desktop').hide();
        }

        /* 1 = work */
        if(purpose == '1'){
            $('body').find('#work-section-desktop').show();
        }else{
            $('body').find('#work-section-desktop').hide();
        }

        /* 4 = general health */ 
        if(purpose == '4'){
            $('body').find('#general-health-section-desktop').show();
        }else{
            $('body').find('#general-health-section-desktop').hide();
        }

        /* 5 = STD Testing */
        if(purpose == '5'){
            $('body').find('#std-section-desktop').show();
        }else{
            $('body').find('#std-section-desktop').hide();
        }

        /*6 = other */
        if(purpose != '6'){
            $('body').find('#visit_purpose_other').prop('disabled', true);
            $('body').find('#visit_purpose_other_wrap').hide();
        }else{
            $('body').find('#visit_purpose_other').prop('disabled', false);
            $('body').find('#visit_purpose_other_wrap').show();
        }

        /*9 = Post-Exposure Rabies Consultation */
        if(purpose != '9'){
            $('body').find('#post_exposure_rabies_consultation').hide();
        }else{
            $('body').find('#post_exposure_rabies_consultation').show();
        }
    });
    /*END Travel select dropdown*/

    /*Travel Country Plus*/
    $('#travel_country_plus').click(function(){
        var initial_count = $('#initial_country_count').val();
        initial_count++;

        if (confirm('Are you sure you would like to add another country selection?')) {
            $.ajax({
                type: "post",
                url: APP_URL+'addTravelCountryPlus',
                data: {
                    initial_count: initial_count,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    $('#initial_country_count').val(initial_count);
                    $('#travel_country_plus_wrapper').append(data.html);
                }
            });
        }
    });
    /*END Travel Country Plus*/

    /*Select Only one Checkbox*/
    $('body').on('change', '.fa_id', function() {
        $('body').find('.fa_id').not(this).prop('checked', false);
    });

    $('body').on('change', '.site', function() {
        $('body').find('.site').not(this).prop('checked', false);
    });

    $('body').on('change', '#previous_condition input[type="checkbox"]', function() {
        var id = $(this).data('id');
        $('body').find('#previous_condition input.previous_condition_'+id).not(this).prop('checked', false);  
    });

    $('body').on('change', '#psyhical_exam_disease input[type="checkbox"]', function() {
        var id = $(this).data('id');
        $('body').find('#psyhical_exam_disease input.previous_condition_'+id).not(this).prop('checked', false);  
    });

    $('body').on('change', '#previous_vaccines input[type="checkbox"]', function() {
        var id = $(this).data('id');
        $('body').find('#previous_vaccines input.previous_vaccines_'+id).not(this).prop('checked', false);  
    });

    $('body').on('change', 'input.use_tobacco', function() {
        $('body').find('input.use_tobacco').not(this).prop('checked', false);  
    });

    $('body').on('change', '.md_pa_check', function() {
        $('body').find('.md_pa_check').not(this).prop('checked', false);
    });
    /*END Select Only one Checkbox*/
    /*Urinalysis Form*/
    $('body').on('change', '[name="color"]', function() {
        var color = $(this).val();
        if(color=='7'){
            $('body').find('#color_other').show();
        }else{
            $('body').find('#color_other').hide();
        }
    });
    /*End Urinalysis Form*/
    /*Paid By*/
    $('body').on('change', '#paid_by_top', function() {
        var paid_by = $('body').find( "#paid_by_top" ).val();
        if(paid_by == 'company' || paid_by == 'school'){
            $('body').find('#paid_by_details_top').show();
        }else{
            $('body').find('#paid_by_details_top').hide();
        }

        $('body').find('#paid_by').val(paid_by);
    });

    $('body').on('keyup','#paid_by_details_top', function(e) {
        var paid_by_details = $('body').find( "#paid_by_details_top" ).val();
        $('body').find('#paid_by_details').val(paid_by_details);
    });
    /*End Paid By*/
    /*travel_purpose_other*/
    $('body').on('change', '#travel_purpose', function() {
        var purpose = $('body').find( "#travel_purpose" ).val();
        /* 9 = Other */
        if(purpose == '9'){
            $('body').find('#travel_purpose_other').show();
        }else{
            $('body').find('#travel_purpose_other').hide();
        }
    });
    /*END travel_purpose_other*/
    /*travel_activity_other*/ 
    $('body').on('change', '#travel_activity', function() {
        var purpose = $('body').find( "#travel_activity" ).val();
        /* 11 = Other */
        if(purpose == '11'){
            $('body').find('#travel_activity_other').show();
        }else{
            $('body').find('#travel_activity_other').hide();
        }
    });
    /*END travel_activity_other*/
    /*school_services_needed_other*/ 
    $('body').on('change', '#school_services_needed  input[type="checkbox"]', function() {
        var purpose = $(this).val();
        /*Check only TB Skin Test or Quantiferon*/
        if(purpose==2){
            $("#school_services_needed input[type=checkbox][value=3]").prop("checked",false);
        }

        if(purpose==3){
            $("#school_services_needed input[type=checkbox][value=2]").prop("checked",false);
        }
        /********************/
        /* 8 = Other */
        if(purpose == '8'){
            $('body').find('#school_services_needed_other').toggle();
            $('body').find('#work_services_needed_other').val('');
        } 
    });
    /*END travel_activity_other*/
    /*work_services_needed_other*/
    $('body').on('change', '#work_services_needed input[type="checkbox"]' , function() {
        var purpose = $(this).val();
        /*Check only TB Skin Test or Quantiferon*/
        if(purpose==2){
            $("#work_services_needed input[type=checkbox][value=3]").prop("checked",false);
        }

        if(purpose==3){
            $("#work_services_needed input[type=checkbox][value=2]").prop("checked",false);
        }
        /********************/
        /* 8 = Other */
        if(purpose == '8'){
            $('body').find('#work_services_needed_other').toggle();
            $('body').find('#work_services_needed_other').val('');
        }
    });
    /*END travel_activity_other*/
    /*general_health_services_needed_other*/
    $('body').on('change', '#general_health_services_needed input[type="checkbox"]', function() {
        var purpose = $(this).val();
        /*Check only TB Skin Test or Quantiferon*/
        if(purpose==3){
            $("#general_health_services_needed input[type=checkbox][value=4]").prop("checked",false);
        }

        if(purpose==4){
            $("#general_health_services_needed input[type=checkbox][value=3]").prop("checked",false);
        }
        /********************/
        /* 10 = Other */
        if(purpose == '10'){
            $('body').find('#general_health_services_needed_other').toggle();
            $('body').find('#general_health_services_needed_other').val('');
        }
    });
    /*END travel_activity_other*/ 
    /*chest_x_ray_other*/
    $('body').on('change','.reason_performing', function() {
        var purpose = $('body').find( this ).val();
        /* 3 = Other */
        if(purpose == '3'){
            $('body').find('#reason_performing_other').show();
        }else{
            $('body').find('#reason_performing_other').hide();
        }
    });
    /*END chest_x_ray_other*/ 
    /*alergies_associated_reactions_other*/
    $('body').on('change','.alergies_associated_reactions', function() {
        var id = $(this).data('selectid');
        var row = $(this).data('row');
        var purpose = $('body').find( "#alergies_associated_reactions"+row ).val();
        /* 5 = Other */
        if(purpose == '5'){
            $('body').find('#alergies_associated_reactions_other'+row).show();
        }else{
            $('body').find('#alergies_associated_reactions_other'+row).hide();
        }
    });

    $('body').find('#alergies_reactions').bind('input propertychange', function() {
        $('body').find("#allergie-reactions").hide();
        if(this.value.length){
            $('body').find("#allergie-reactions").show();
        }
    });
    /*END alergies_associated_reactions_other*/ 
    if($('body').find('[name="any_alergies"]').is(':checked')){
        $('body').find('#alergies_reactions').attr('disabled',true);
    }

    if($('body').find('[name="international_address"]').is(':checked')){
        $('body').find('#street').attr('disabled',true);
        $('body').find('#city').attr('disabled',true);
        $('body').find('#state').attr('disabled',true);
        $('body').find('#zip').attr('disabled',true);
    }


    if($('#previous_surgeries_slide').is(':checked')) {
        $('#previous_surgeries_checkboxes :input').attr('disabled',true);
    }

    if($('body').find('#none_medications_currently_taking').is(':checked')) {
        $('body').find('#medications_currently_taking_checkboxes :input').attr('disabled',true);
    }

    if($('body').find('#none_medical_conditions').is(':checked')) {
        $('body').find('#medical_conditions_checkboxes :input').attr('disabled',true);
    }



    $('body').on('change', '[name="any_alergies"]', function(){
        if($(this).is(':checked')) {
            $('body').find('.alergies_reactions').val('');
            $('body').find(".allergie-reactions").hide();
            $('body').find('.alergies_associated_reactions_other').val('').hide();
            $('body').find('.alergies_associated_reactions').val('');
            $('body').find( "#any_alergies_slide" ).slideUp( "hide" );
        }else{
            $('body').find('#alergies_reactions').attr('disabled',false);
            if($('body').find('#alergies_reactions').val()!=""){
                $('body').find("#allergie-reactions").show();
            }
            $('body').find( "#any_alergies_slide" ).slideDown( "slow" );
        } 
    });

    $('body').on('change', '#none_prev_surgery', function() {
        if($(this).is(':checked')) {
            $('body').find('#previous_surgeries_checkboxes :input').attr('disabled',true).val('');
            $('body').find( "#previous_surgeries_slide" ).slideUp("hide");
        }else{
            $('body').find('#previous_surgeries_checkboxes :input').attr('disabled',false);
            $('body').find( "#previous_surgeries_slide" ).slideDown("show");
        } 
    });

    $('body').on('change', '#none_medications_currently_taking', function() {
        if($(this).is(':checked')) {
            $('body').find('#medications_currently_taking_checkboxes :input').attr('disabled',true).val('');
            $('body').find('#none_medications_currently_taking_slide').slideUp('hide');
        }else{
            $('body').find('#medications_currently_taking_checkboxes :input').attr('disabled',false);
            $('body').find('#none_medications_currently_taking_slide').slideDown('slow');
        } 
    });

    $('body').on('change', '#none_medical_conditions', function() {
        if($(this).is(':checked')) {
            $('body').find('#medical_conditions_checkboxes :input').attr('disabled',true).val('');
            $('body').find('#none_medical_conditions_slide').slideUp('hide');
        }else{
            $('body').find('#medical_conditions_checkboxes :input').attr('disabled',false);
            $('body').find('#none_medical_conditions_slide').slideDown('slow');
        } 
    });
    /*NONE Checkout*/
    /*End None Checkout*/

    /* Admin Physical Exam */
    $('body').on('change','#limitations_yes, #limitations_no', function() {
        if($('#limitations_yes').is(':checked')) {
            $('.limitations_explain').show();
        }else{
            $('.limitations_explain').hide();
        } 
    });

    $('body').on('change','#special_accommodations_yes, #special_accommodations_no', function() {
        if($('#special_accommodations_yes').is(':checked')) {
            $('.special_accommodations_explain').show();
        }else{
            $('.special_accommodations_explain').hide();
        } 
    });
    /* END Admin Physical Exam */

    /*Chest X-Ray Questionnaire Form*/
    $('body').on('change','#use_tabacco_yes, #use_tabacco_no', function() {
        if($('#use_tabacco_yes').is(':checked')) {
            $('.chest-xray-use-tobacco').show();
        }else{
            $('.chest-xray-use-tobacco').hide();
        } 
    });

    $('body').on('change','#question_donot_yes,#question_donot_no', function() {
        if($('#question_donot_yes').is(':checked')) {
            $('.chest-xray-donot-use-tobacco').show();
        }else{
            $('.chest-xray-donot-use-tobacco').hide();
        } 
    });

    $('body').on('change','#abnormal_chestxray_yes, #abnormal_chestxray_no', function() {
        if($('#abnormal_chestxray_yes').is(':checked')) {
            $('.chest-xray-abnormal').show();
        }else{
            $('.chest-xray-abnormal').hide();
        } 
    });
    /*End Chest X-Ray Questionnaire Form*/

    /*Post Deployent Form */
    /* $('body').on('change','.wounded_injured_yes, .wounded_injured_no', function() {
        if($('.wounded_injured_yes').is(':checked')) {
            $('#wounded_injured1').show();
        }else{
            $('#wounded_injured1').hide();
        } 
    }); */

    if($('.wounded_injured_yes').is(':checked')) {
        $('#wounded_injured1').show();
    }

    $('body').on('change','#wonded_injured_1_yes, #wonded_injured_1_no', function() {
        if($('#wonded_injured_1_yes').is(':checked')) {
            $('#wonded_injured_explain').show();
        }else{
            $('#wonded_injured_explain').hide();
        } 
    });

    if($('#wonded_injured_1_yes').is(':checked')) {
        $('#wonded_injured_explain').show();
    }

    $('body').on('change','.q3_input', function() {
        if($('#q3_worse').is(':checked')) {
            $('#q3_explain_worse').show();
        }else{
            $('#q3_explain_worse').hide();
        } 

        if($('#q3_much_worse').is(':checked')) {
            $('#q3_explain_much_worse').show();
        }else{
            $('#q3_explain_much_worse').hide();
        } 
    });

    if($('#q3_worse').is(':checked')) {
        $('#q3_explain_worse').show();
    }

    if($('#q3_much_worse').is(':checked')) {
        $('#q3_explain_much_worse').show();
    }

    $('body').on('change','.q4_input', function() {
        if($('#q4_other').is(':checked')) {
            $('#q4_explain').show();
        }else{
            $('#q4_explain').hide();
        } 
    });

    if($('#q4_other').is(':checked')) {
        $('#q4_explain').show();
    }

    $('body').on('change','.q5_input', function() {
        if($('#q5_anti_m').is(':checked')) {
            $('#q5_explain_anti_m').show();
        }else{
            $('#q5_explain_anti_m').hide();
        } 

        if($('#q5_other').is(':checked')) {
            $('#q5_explain_other').show();
        }else{
            $('#q5_explain_other').hide();
        } 
    });

    if($('#q5_anti_m').is(':checked')) {
        $('#q5_explain_anti_m').show();
    }

    $('body').on('change','#q10_yes, #q10_no', function() {
        if($('#q10_yes').is(':checked')) {
            $('#q10_explain').show();
        }else{
            $('#q10_explain').hide();
        } 
    });

    if($('#q5_other').is(':checked')) {
        $('#q5_explain_other').show();
    }

    $('body').on('change','.q11_input', function() {
        if($('#q11_yes').is(':checked')) {
            $('#q11_explain').show();
        }else{
            $('#q11_explain').hide();
        } 
    });

    if($('#q11_yes').is(':checked')) {
        $('#q11_explain').show();
    }

    $('body').on('change', '#q7_b_yes, #q7_b_no', function () {
        if ($('#q7_b_yes').is(':checked')) {
            $('#q7_b_explain').show();
        } else {
            $('#q7_b_explain').hide();
        }
    });

    if ($('#q7_b_yes').is(':checked')) {
        $('#q7_b_explain').show();
    }

    $('body').on('change','#q17_yes, #q17_no', function() {
        if($('#q17_yes').is(':checked')) {
            $('#q17_explain').show();
        }else{
            $('#q17_explain').hide();
        } 
    });

     if($('#q17_yes').is(':checked')) {
        $('#q17_explain').show();
    }

    /* End Post Deployement Form */

    /* International Address Checkbox */
    if($('body').find('[name="international_address"]').is(':checked')){
        $('body').find('#street').attr('disabled',true);
        $('body').find('#city').attr('disabled',true);
        $('body').find('#state').attr('disabled',true);
        $('body').find('#zip').attr('disabled',true);
    }

    $('body').on('change','[name="international_address"]', function() {
        if($(this).is(':checked')) {
            $('body').find('#street').attr('disabled',true).val('');
            $('body').find('#city').attr('disabled',true).val('');
            $('body').find('#state').attr('disabled',true).val('');
            $('body').find('#zip').attr('disabled',true).val('');

            $('body').find( "#international_address_slide" ).slideUp("hide");
        }else{
            $('body').find('#street').attr('disabled',false).val('');
            $('body').find('#city').attr('disabled',false).val('');
            $('body').find('#state').attr('disabled',false).val('');
            $('body').find('#zip').attr('disabled',false).val('');

            $('body').find( "#international_address_slide" ).slideDown("show");
        } 
    });
    /* END International Address Checkbox */


    /* Tb Skin Close validation */
    var tbSkinClose = true; 
    $('body').on('change', '#rec-tuberculosis-section-desktop #return_date,#rec-tuberculosis-section-desktop #return_time,#rec-tuberculosis-section-desktop .input_date, #rec-tuberculosis-section-desktop .option-input, #rec-tuberculosis-section-desktop .form-control', function () { 
        tbSkinClose = false;
    });

    var formClose = true;
/*     $('body').on('change','#patientsListModal', function () { 
        var isFormsModalOpen = $('body').find('#isFormsModalOpen').val();
        if (isFormsModalOpen=='open') {
            var formClose = false;
            console.log('pula');
        }
    })


    $('body').on('click', '#closeFormModal,.modalXClose', function () { 
        if(formClose==false) {
            alert('pula cu piele');
            formClose = true;
            return;
        }
    }) */
    /* ********************** */


    /*Patients List Ajax Modal*/
    //$('.load-ajax-modal').click(function(){
    $('body').on('click','.load-ajax-modal',function(e) {
        $('#dynamic-modal div.modal-body').html('');
        var id = $(this).attr('id');

        $.ajax({
            type : 'GET',
            url : $(this).data('path'),
            success: function(data, statusText, xhr) {
                if(typeof(data.alertPin) != "undefined") {
                    $('#tbskinPin').modal('show');
                    localStorage.setItem("tbskinButtonId", id);
                    return;
                }
                
                $('#dynamic-modal div.modal-body').html(data.html);
                if(typeof(data.form_id) != "undefined" && data.form_id==7){
                    interval = data.interval;
                    db_calendar_slots = data.db_calendar_slots
                }

                if(typeof(data.form_id) != "undefined" && data.form_id==57){
                    $('.removeModalLg').removeClass('modal-lg');
                    $('.modalScroll1').addClass('modalScroll11');
                    $('.modalScroll2').addClass('modalScroll22');
                } else {
                    $('.removeModalLg').addClass('modal-lg');
                    $('.modalScroll1').removeClass('modalScroll11');
                    $('.modalScroll2').removeClass('modalScroll22');
                }

                if(data.update){
                    $('#partial_row'+data.id_form+' .review-status-wrap img').attr('src','../img/checked-icon-green.svg');

                    if(data.patientFormsStatus==0)
                        src = '../img/checked-icon-grey.svg';
                    else if(data.patientFormsStatus==1)
                        src = '../img/checked-icon-green.svg';
                    else if(data.patientFormsStatus==2)
                        src = '../img/warning.svg';

                    $("#imgReviewStatus"+data.rowid+' img').attr('src', src);
                }

                $('#dynamic-modal').modal('show');

                tbSkinClose = true; 
            } 
        });
    });
    /*END Patients List Ajax Modal*/

    /*Date Interval Inputs Start*/
    $('body').on('click', '#return_date_button', function(){
        $('body').find('.return_overlay').hide();
        $('body').find('#return_overlay').val(0);
    })


    $('body').on('change','select[name="return_date"]',function() { 
        var selectDay = $('body').find('select[name="return_date"]').val();
        var hours = interval['hours'][selectDay];
        var optionsString = "";

        
        for(var i = 0; i < hours.length; i++) {
            console.log(db_calendar_slots,hours[i],db_calendar_slots[hours[i]])
            if(typeof(db_calendar_slots[hours[i]]) != "undefined" ){
                optionsString += "<option data-slotid='"+db_calendar_slots[hours[i]]+"' value='" + hours[i] + "'>" + hours[i] + "</option>";
            }
        }
        $('body').find( 'select[name="return_time"]' ).html('').append( optionsString );
    });
    /*END Date Interval Inputs Start*/

    /*Patient List Modal Form Submit*/
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        if(hours<10)
            hours = '0'+hours;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        return strTime;
    }

    function getTodayDate(date) {
        var day = date.getDate();
        var month = date.getMonth()+1;
        var year = date.getFullYear();

        var m = '';
        if(month < 10){
            m = '0';
        }

        var d = '';
        if(day < 10){
            d = '0';
        }

        return m+month + '-' + d+day + '-' + year;
    }

    $('body').on('change','.site', function(e) {
        var ex_date = $('body').find('input[name="ex_date"]').val();
        var target_name = e.currentTarget.name;
        if(ex_date.length == 0 && target_name == 'ex_date')
            return;

        var dateAdm = $('body').find('input[name="date_administrated"]').val()
        var timeAdm = $('body').find('input[name="time"]').val()
        if(dateAdm.length == 0 && timeAdm.length == 0){
            var date = new Date();
            var hour = formatAMPM(date);
            var date = getTodayDate(date);
            $('body').find('input[name="date_administrated"]').val(date)
            $('body').find('input[name="time"]').val(hour)
        }
    });

    $('body').on('keyup','.site', function(e) {
        var dateAdm = $('body').find('input[name="date_administrated"]').val()
        var timeAdm = $('body').find('input[name="time"]').val()
        if(dateAdm.length == 0 && timeAdm.length == 0){
            var date = new Date();
            var hour = formatAMPM(date);
            var date = getTodayDate(date);
            $('body').find('input[name="date_administrated"]').val(date)
            $('body').find('input[name="time"]').val(hour)
        }
    });

    $('body').on('click', '#ignore_safety_request', function (e) {
        $('body').find('#testReadWrapOverlay').hide();
    })

    $("#date_test_read").datepicker({
        onSelect: function() {
            var alertDateTimeSafetyReq = $('#alertDateTimeSafetyReq').val();
            var alertDateTimeSafetyReqMessage = $('#alertDateTimeSafetyReqMessage').val();

            /*if (alertDateTimeSafetyReq=='true') {
                $('.enduration').val('');
                $('.date_test_read').val('');
                $("#p_n")[0].selectedIndex = 0
                $("#time_test_read")[0].selectedIndex = 0
                alert(alertDateTimeSafetyReqMessage);
                return;
            }*/
        }
    });

    $('body').on('change','#p_n,#time_test_read,#date_test_read', function(e) {
        var alertDateTimeSafetyReq = $('#alertDateTimeSafetyReq').val();
        var alertDateTimeSafetyReqMessage = $('#alertDateTimeSafetyReqMessage').val();

        /*if (alertDateTimeSafetyReq=='true') {
            $('.enduration').val('');
            $('.date_test_read').val('');
            $("#p_n")[0].selectedIndex = 0
            $("#time_test_read")[0].selectedIndex = 0
            alert(alertDateTimeSafetyReqMessage);
            return;
        }*/
    })

    $('body').on('keyup','.enduration', function(e) {
        var value = $(this).val();
        var alertDateTimeSafetyReq = $('#alertDateTimeSafetyReq').val();
        var alertDateTimeSafetyReqMessage = $('#alertDateTimeSafetyReqMessage').val();

        /*if (alertDateTimeSafetyReq=='true') {
            $('.enduration').val('');
            $('.date_test_read').val('');
            $("#p_n")[0].selectedIndex = 0
            $("#time_test_read")[0].selectedIndex = 0
            alert(alertDateTimeSafetyReqMessage);
            return;
        }*/
        
        if(value>=10) {
            $("#p_n").val('Positive');
        } else if(value=="") {
            $("#p_n")[0].selectedIndex = 0
        } else {
            $("#p_n").val('Negative');
        }
        var date = new Date();
        var select = formatAMPM(date);
        var date = getTodayDate(date);
        $('body').find('#time_test_read>option[value="'+select+'"]').prop("selected", true);
        $('body').find('.date_test_read').val(date);
    });

    $('body').on('click', '.showInitialsWrap', function(e) {
        $(this).find('.hideText').hide();
        $(this).find('.showInitials').show();

        var initialArr = $(this).data('initialarr');
        var initialsArr = $('body').find('#mainInitial').val();
        $('#' + initialArr).val(initialsArr)
        
        var initials = $('body').find('.initialsClick').val();
        $(this).find('.patient-initials').val(initials);
    })

    $('body').on('keyup','.patient-initials', function(e) {
         $(this).val($(this).val().toUpperCase());
    });

    /*$('body').on('submit','#patientsListModal',function(e) {*/
    $('body').on('click', '.submit-form-modal', function(e){
        var form = $('body').find("#patientsListModal");

        var url = form.attr('action');

        var tab = $(this).data('tab');
        var editable = $(this).data('editable')
        if(typeof(editable) != "undefined" && editable=='no'){
            if (confirm('Are you sure? No more edits can be made once saved!')) {
                $("form#patientsListModal").append("<input name='editable' id='editable_form_input' value='no' type='hidden'/>")
            } else {
                return;
            }

        }
        $('#form_tab_request').val(tab);

        $('.submit-form-modal').attr('disabled',true);

        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: url,
                data: $("#patientsListModal").serialize(),
                success: function(data)
                {   
                    $('#editable_form_input').remove()
                    $('.submit-form-modal').attr('disabled',false);
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if (data.has_isFormsInterface==true) {
                        window.location = data.redirect;
                        return;
                    }

                    if(typeof(data.liveVaccine28Days) != "undefined" && data.liveVaccine28Days==true) {
                        alert("Live vaccines has been administered, reschedule tb test to 28 days or later");
                        return;
                    }

                    if ((/* data.id_form == 7 || */ data.id_form == 17 || data.id_form == 15 || data.id_form == 27 || data.id_form ==45 || data.id_form ==49) && data.allInputsCompleted == true && data.success == true){
                        //if (confirm('"Are you sure you would like to upload this form to Patient Files?')) {
                            $('body').find('.row_collapse_'+data.id_patient).click();
                            setTimeout( function (){
                                $('body').find('.row_collapse_'+data.id_patient).click();
                            }
                            , 750);
                            $('body').find('#save_form_to_office_files').attr("disabled", false);
                            flag = true;
                            tbSkinClose = true; 

                            tab = data.id_form == 49 ? 'office' : tab;
                            
                            $.ajax({
                                type: "post",
                                url: APP_URL+'generatePDFtoPatientFiles',
                                data: {
                                    id_form: data.id_form,
                                    id_patient: data.id_patient,
                                    id_visit_purpose: data.id_visit_purpose,
                                    id_record: data.id_record,
                                    tab: (data.id_form == 17 || data.id_form == 27 || data.id_form == 49) ? tab : 'patient',
                                    _token: $('[name="_token"]').val()
                                },
                                success: function(d)
                                {   
                                    $('body').find('#server-message').html('').html(d.message);
                                    $('body').find('.row_collapse_'+data.id_patient).click();
                                    setTimeout( function (){
                                        $('body').find('.row_collapse_'+data.id_patient).click();
                                    }
                                    , 750);
                                    //$('body').find('#files-listing-patient-'+data.id_patient).html('').html(d.files);
                                    $('body').find('#save_form_to_office_files').attr("disabled", false);
                                    flag = true;
                                    tbSkinClose = true; 
                                    return;
                                }
                            });
                        //}
                    }else if(data.success==true){
                        $('#registerFormDetailsModal').modal('hide');
                        $('#dynamic-modal').modal('hide'); 
                        if(data.reload==true)
                            location.reload();

                        $('body').find('#save_form_to_office_files').attr("disabled", false);
                        $('body').find('#save_form_to_patient_files').attr("disabled", false);
                        tbSkinClose = true; 
                    }


                    if(typeof(data.tbLabelHref) != "undefined" && data.tbLabelHref!='') {
                        if (confirm('Print TB Label?')) {
                            window.open(data.tbLabelHref, '_blank').focus();
                        }
                    }

                    $('#server-message').html('').html(data.html);
                    $('body').on('shown.bs.modal','#dynamic-modal', function () {
                        $('#dynamic-modal').animate({ scrollTop: 0 }, 'slow');
                    });
                    flag = true;
                    formClose = true;

                    $('body').find('.row_collapse_' + data.id_patient).click();
                    setTimeout(function () {
                        $('body').find('.row_collapse_' + data.id_patient).click();
                    }
                    , 750);

                    if(href!='' && data.success==true) {
                        window.open(href, '_blank').focus();
                    }

                    href = ''
                }
            });
        }
        e.preventDefault();
    });
    /*END Patient List Modal Form Submit*/


    /*PATIENTS CODE*/
    /*$('.registerFormDetails').click(function(){
        alert('test');
        $.ajax({
            type : 'GET',
            url : $(this).data('path'),
            success: function(data, statusText, xhr) {
                $('#dynamic-modal div.modal-body').html(data.html);
            }
        }); 
    });*/

    $('body').on('change','#p_n',function() {
        var val = $(this).val();
        if(val=='Positive'){
            var d = new Date();
            var datestring = (d.getMonth()+1)  + "-" + d.getDate() + "-" + d.getFullYear();

            var dateTestRead = $('body').find('input[name="date_test_read"]').val();

            if($('body').find('input[name="date"]').val().length == 0){
                $('body').find('input[name="date"]').val(dateTestRead);
            }

            if($('body').find('input[name="positive_test_staff_date"]').val().length == 0){
                $('body').find('input[name="positive_test_staff_date"]').val(dateTestRead);
            }
        }else{
            $('body').find('input[name="date"]').val(datestring);
            $('body').find('input[name="positive_test_staff_date"]').val(datestring);
        }
    });

    $('#patients_list').on('click','.vp_notes_modal', function(e) {
        var id_visit_purpose = $(this).data('visitpurpose');
        var id_patient = $(this).data('idpatient');
        
        if(flag==true){
            flag = false;
            $.ajax({
                url: APP_URL+'visitPurposeNotesModal',
                method:'post',
                data:{
                    id_patient: id_patient,
                    id_visit_purpose: id_visit_purpose,
                    _token: $("#csrf-token").val()
                }
            }).done(function(data){
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                $('#visitPurposeNotesModal div.modal-body').html(data.html);
                setTimeout(function () { $('#vpnm_note').focus(); }, 1000);
            });
            flag = true;
        }
    });

    /*Add New Visit*/
    
    $('#patients_list').on('click', '.add-new-visit', function (e) {
        

        var twoOrMore = $('#twoOrMore').val();

        var first_name = $(this).data('firstname');
        var middle_name = $(this).data('middlename');
        var last_name = $(this).data('lastname');
        var id_patient = $(this).data('idpatient');

        var id_user = $(this).data('iduser');

        if (twoOrMore == 1) {
            if (confirm('Are you sure this is the correct patient, first name, middle name if available, last name, date of birth?')) {
                twoOrMoreF(first_name, middle_name, last_name, id_patient, id_user);
            }
        } else {
            twoOrMoreF(first_name, middle_name, last_name, id_patient, id_user);
        }
    });

    function twoOrMoreF(first_name, middle_name, last_name, id_patient, id_user) {
        if (confirm('Are you sure you want to generate a new visit for ' + first_name + ' ' + middle_name + ' ' + last_name + ' patient?')) {
            if (flag == true) {
                flag = false;
                $.ajax({
                    url: APP_URL + 'addNewVisit',
                    method: 'post',
                    data: {
                        id_user: id_user,
                        _token: $("#csrf-token").val()
                    }
                }).done(function (data) {
                    if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                        window.location = data.redirect;
                        return;
                    }

                    if (data.success == true) {
                        $('body').find('.row_collapse_' + id_patient).click();
                        setTimeout(function () {
                            $('body').find('.row_collapse_' + id_patient).click();
                        }
                            , 750);
                    }
                });
                flag = true;
            }
        }
    }

    /*END Add New Visit*/

    $("#patients_list").on('click','.registerFormDetails,.partialFormDetails', function (e) {
        var detailsUrl = $('#detailsUrl').val();
        var partialsUrl = $('#partialsUrl').val();
        var detailsUrlStaff = $('#visitPurposeDetails').val();

        var visitPurpose = $(this).data('visitpurpose');
        var partialForm = $(this).data('partialform');
        var recordid = $(this).data('recordid');
        var formType = $(this).data('formtype');
        var id_user = $(this).data('iduser');

        if(formType=='detail'){ 
            var getUrl = detailsUrl;
        }

        if(formType=='partial'){
            var getUrl = partialsUrl;
        }

        if(getUrl==undefined)
            var getUrl = detailsUrlStaff;

        
        request = $.ajax({
            url: getUrl,
            method: "POST",
            data: { 
                visitPurpose: visitPurpose,
                partialForm: partialForm,
                recordid: recordid,
                id_user: id_user,
                _token: $('[name="_token"]').val()
            },
            dataType: "json"
        }).done(function (data, statusText, xhr) {
            if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                window.location = data.redirect;
                return;
            }

            $('#registerFormDetailsModal div.modal-body').html(data.html);
            
        }).fail(function() {
            /*alert( "error" );*/ 
        });
    });

    $('body').on('change','select[name="push_form"]',function() {
        var form_id = $(this).val();
        $('.add-form').attr('data-id', form_id);
    });

    $('body').on('click','.add-form',function() {
        var visitpurpose = $(this).data('visitpurpose');
        var id_register = $(this).data('idregister');
        var form_id = $('#selectForm'+visitpurpose).val();
        var id_clinician = $("body").find('#id_clinician_selected_modal').val();
        var clinician_safety_reason = $("body").find('#clinician_safety_reason').val();

        var vp = $(this).data('visitpurpose');
        var val = $('body').find('#selectForm' + vp).val();

        $('.add-form').attr('disabled', true);

        if(flag==true){
            flag = false;
            $.ajax({
                url: APP_URL+'pushForm',
                method:'post',
                data:{
                    id_register: id_register,
                    form_id :form_id,
                    visitpurpose: visitpurpose,
                    id_clinician_safety: id_clinician,
                    clinician_safety_reason: clinician_safety_reason,
                    _token: $("#csrf-token").val()
                }
            }).done(function(data){
                $('.add-form').attr('disabled', false);
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                $('body #selectForm' + vp +" option[value = "+ val +"]").attr("disabled", "disabled");

                if(typeof(data.alert) != "undefined") {
                    alert(data.alert);
                    return;
                }

                $('body').find('.consentVaccineAuthorisationModalBody').html('');
                if (typeof (data.modalApprove) != "undefined" && (typeof id_clinician == "undefined" || id_clinician == '')) {
                    setTimeout(function () {
                        $("#consentVaccineAuthorisationModal").modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                    }, 1000);


                    $('body').find('.consentVaccineAuthorisationModalBody').html('').html(data.modalApprove);
                    return;
                }

                if(data.success == false) {
                    return;
                }

                $('body').find('.consentVaccineAuthorisationModalBody').html('');
                if(typeof(data.modalApprove) != "undefined" && (typeof id_clinician == "undefined" || id_clinician == '')){
                    setTimeout(function(){
                        $("#consentVaccineAuthorisationModal").modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                    },1000);


                    $('body').find('.consentVaccineAuthorisationModalBody').html('').html(data.modalApprove);
                    return;
                } else {
                    $('#pushFormA'+visitpurpose).prepend(data.html);

                    if(data.patientFormsStatus==0)
                        src = '../img/checked-icon-grey.svg';
                    else if(data.patientFormsStatus==1)
                        src = '../img/checked-icon-green.svg';
                    else if(data.patientFormsStatus==2)
                        src = '../img/warning.svg';
                    $("#imgReviewStatus"+id_register+' img').attr('src', src);
                    $('#selectForm'+visitpurpose).prop('selectedIndex',0);
                }
                
            });
            flag = true;
        }
    });

/*    $("#reset").on('click', function (e) {
        $("#search_field, #dob, #gender, #phone, #date_start, #date_end").val("");
        var first =  $("#id_form > option:first").val();
        if(first !=""){
            $("#id_form > option:first").remove();
        }
        $("#search-form").submit();  
    });*/

    if($('#decline_first').is(':checked')) {
        $('#initial1').val('');
        $('#initial1').prop('readonly', true);
    }

    if($('#decline_second').is(':checked')) {
        $('#initial2').val('');
        $('#initial2').prop('readonly', true);
    }

    if ($('#decline_third').is(':checked')) {
        $('#initial3').val('');
        $('#initial3').prop('readonly', true);
    }

    $('#decline_first').on('change', function() {
        if($(this).is(':checked')) {
            $('.decline_auth').not(this).prop('checked', false);  
            $('#initial1').val('');
            $('#initial1').prop('readonly', true);
            $('#initial2').prop('readonly', false);
            $('#initial3').prop('readonly', false);
        }else{
           // $('.decline_auth').not(this).prop('checked', true);  
            //$('#initial2').prop('readonly', true);
            //$('#initial2').val('');
            $('#initial1').prop('readonly', false);
        }
    });

    $('#decline_second').on('change', function() {
        if($(this).is(':checked')) {
            $('.decline_auth').not(this).prop('checked', false);  
            $('#initial2').val('');
            $('#initial1').prop('readonly', false);
            $('#initial2').prop('readonly', true);
            $('#initial3').prop('readonly', false);
        }else{
            //$('.decline_auth').not(this).prop('checked', true);  
            //$('#initial1').prop('readonly', true);
            //$('#initial1').val('');
            $('#initial2').prop('readonly', false);
        }
    });

    $('#decline_third').on('change', function () {
        if ($(this).is(':checked')) {
            $('.decline_auth').not(this).prop('checked', false);
            $('#initial3').val('');
            $('#initial1').prop('readonly', false);
            $('#initial2').prop('readonly', false);
            $('#initial3').prop('readonly', true);
        } else {
            //$('.decline_auth').not(this).prop('checked', true);  
            //$('#initial1').prop('readonly', true);
            //$('#initial1').val('');
            $('#initial3').prop('readonly', false);
        }
    });
    
    /*END PATIENTS CODE*/

    /*TB Skin*/
    $('body').on('change', '.previous_condition_7', function () {
        var radioValue = $("input[name='rec_questions[7][]']:checked").val();
        if (radioValue=='yes') {
            $(".previous_condition_7").prop("checked", false);
            alert("Live vaccines has been administered, reschedule tb test to 28 days or later");
        }
    });

    $('body').on('change', '.previous_condition_8', function () {
        var radioValue = $("input[name='rec_questions[8][]']:checked").val();
        var typeValue = $('#tb_skin input[name=type]:checked').val();

        console.log(radioValue,typeValue);
        if(typeValue==1) {
            var displayOverlayInputValue = $('body').find('#displayOverlayInput').val();
            if (radioValue == 'yes' || displayOverlayInputValue=='yes') {
                $('body').find('.overlayTB').show();
            }else {
                $('body').find('.overlayTB').hide();
            }
        }
    });

    ///////

    $('body').on('change','.tb_step', function() {
        var step = $(this).val();
        var lastTbSkinReadDays = $('#lastTbSkinReadDays').val()
        if (step == 1 && lastTbSkinReadDays<=21){
            $('#selected_step').find('option').remove().end().append($('<option>',
            {
                value: 1,
                text : 1 
            }));

            $('#max_step').find('option').remove().end().append($('<option>',
            {
                value: 1,
                text : 1 
            }));
        }

        if (step == 1 && lastTbSkinReadDays > 21) {
            $('#selected_step').find('option').remove().end().append($('<option>',
            {
                value: 1,
                text: 1
            }));

            $('#max_step').find('option').remove().end().append($('<option>',
            {
                value: 1,
                text: 1
            }));
        }










        if (step == 2 && lastTbSkinReadDays <= 21){ //d
            $('#selected_step').append($('<option>',
            {
                value: 2,
                text : 2 
            }));

            $('#max_step').find('option').remove().end().append($('<option>',
            {
                value: 2,
                text : 2 
            }));
        }

        if (step == 2 && lastTbSkinReadDays > 21) { //d
            $('#selected_step').find('option').remove().end().append($('<option>',
            {
                value: 1,
                text: 1
            }));

            $('#selected_step').append($('<option>',
            {
                value: 2,
                text: 2
            }));

            $('#max_step').find('option').remove().end().append($('<option>',
            {
                value: 1,
                text: 1
            }));

            $('#max_step').find('option').remove().end().append($('<option>',
            {
                value: 2,
                text: 2
            }));
        }

        var selected_step_value = $('body').find('#selected_step_value').val()
        if (selected_step_value == 1) {
            $('#selected_step option[value="1"]').attr("selected", "selected");
        }


        if (selected_step_value == 2) {
            $('#selected_step option[value="2"]').attr("selected", "selected");
        }
    });

    $('body').on('change','#tb_skin input[name=type]', function() {
        if($(this).val()==2){
            $('body').find('.test_quantiferon_hide').hide();
            $('body').find('#steps_section').hide();
            $('body').find('.staffq_signature_qantiferon_wrap').show();
            if ($('body').find('#displayOverlayInput').val()=="yes") {
                $('body').find('.overlayTB').hide();
            }
        }else{
            $('body').find('.test_quantiferon_hide').show();
            $('body').find('#steps_section').show();
            $('body').find('.staffq_signature_qantiferon_wrap').hide();
            if ($('body').find('#displayOverlayInput').val() == "yes") {
                $('body').find('.overlayTB').show();
            }
        }
    });

    /*To change on all dates since are 3 on the page*/
    $('body').on('change','input[name=positive_test_staff_date]', function() {
        var value = $(this).val();
        $('body').find('input[name=positive_test_staff_date]').val(value);
    });
    /*END TB Skin*/

    /*Change Status on Pushed Partial Forms*/
    $('body').on('change','.partial_form_status',function() {
        var partialform = $(this).data('form_to_patient');
        var visitpurpose = $(this).data('visitpurpose');
        var idregister = $(this).data('registerform');
        var status = $(this).val();

        $.ajax({
            url: APP_URL+'changeFormStatus',
            method:'post',
            data:{
                status :status,
                partialform: partialform,
                visitpurpose: visitpurpose,
                id_register: idregister,
                _token: $("#csrf-token").val()
            }
        }).done(function(data){
            if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                window.location = data.redirect;
                return;
            }

            var colors = [
                "partial_add_blue",
                "partial_add_green",
                "partial_add_red"
            ];
            $('#partial'+partialform).removeClass("partial_add_blue");
            $('#partial'+partialform).removeClass("partial_add_red");
            $('#partial'+partialform).removeClass("partial_add_green");

            $('#partial'+partialform).addClass(colors[status]);
            setTimeout(function(){
                $('.server-message'+visitpurpose).hide('slow').html('').show();
            },3000);

            $('.server-message'+visitpurpose).show().html(data.html);
            if(data.delete==true && data.success==true){
                $('body').find('#partial_row'+partialform).html('');
            }

            if(data.patientFormsStatus==0)
                src = '../img/checked-icon-grey.svg';
            else if(data.patientFormsStatus==1)
                src = '../img/checked-icon-green.svg';
            else if(data.patientFormsStatus==2)
                src = '../img/warning.svg';

            $("#imgReviewStatus"+idregister+' img').attr('src', src);
        });
    });


    /*Osha Admin Form*/ 
    $('body').on('change','#rewiew_osha', function() {
        var purpose = $( this ).val();
      
        if(purpose == '3'){
            $('#medical_limitation').show();
        }else{
            $('#medical_limitation').hide();
        }
    });
    /*End Osha*/

    /*Medical Release*/
    $('body').on('change','#authorized_check_indefinitely,#authorized_check_indefinitely', function() {
        if($('#authorized_check_indefinitely').is(':checked')) {
            $('#authorization_expire').val('').hide();
        }else{
            $('#authorization_expire').val('').show();
        }
    });
    /*End Medical Release*/


    /*I6193 Admin Form*/ 

    $('body').on('change','input[name=p5_1c1_find]', function() {
        var val = $( this ).val();
        if(val == 'f'){
            $('#p5_c1_c1f').show();
        }else{
            $('#p5_c1_c1f').hide();
        }

        if(val == 'g'){
            $('#p5_c1_c1g').show();
        }else{
            $('#p5_c1_c1g').hide();
        }
    });

    $('body').on('change','input[name=checkbox_11]', function() {
        var val = $( this ).val();
        if (val == "no") {
            $('#i693Part2').show();
        }else{
            $('#i693Part2').hide()
        }
    });
    /*Code for Required Checkboxes*/
    $('body').on('click','.submit-form-patient', function(e){
    /*$(".submit-form").on('click', function (e) {*/
        var previous_condition_count = $('body').find('#previous_condition').data('previousconditioncount');
        var previous_vaccines_count = $('body').find('#previous_vaccines').data('previousvaccinescount');

        /*Medical Conditions*/
        if($('body').find('div#previous_condition.required :checkbox:checked').length < previous_condition_count){
            //$('body').find('.previous_condition_require').show();
            spc = false;
        }else{
            //$('body').find('.previous_condition_require').hide();
            spc = true;
        }
        /*END Medical Conditions*/

        /*Recent Live Vaccines*/
        if($('body').find('div#previous_vaccines.required :checkbox:checked').length < previous_vaccines_count){
            //$('body').find('.previous_vaccines_require').show();
            spv = false;
        }else{
            //$('body').find('.previous_vaccines_require').hide();
            spv = true;
        }
        /*END Recent Live Vaccines*/

        /*Tobacco*/
        if($('div#use_tobacco.required :checkbox:checked').length == 0){
            //$('.use_tobacco_require').show();
            sut = false;
        }else{
            //$('.use_tobacco_require').hide();
            sut = true;
        }
        /*END Tobacco*/

        /* Medications Currently Taking*/
        var medications_currently_taking = [];
        $('body').find("input[name='medications_currently_taking[]']").each(function() {
            var value = $(this).val();
            if(value.trim()){
                medications_currently_taking.push(value.trim());
            }
        });
        if(medications_currently_taking.length === 0) {
            var medications = true;
        }else{
            var medications = false;
        }

        if($('body').find('#none_medications_currently_taking:checkbox:checked').length == 0 && medications==true){
            snmct = false;
            //$('body').find('.medication_currently_taking_require').show();
        }else{
            snmct = true;
           // $('body').find('.medication_currently_taking_require').hide();
        }
        /*END  Medications Currently Taking*/

        /* Allergies*/
        var alergies_reactions = [];
        $('body').find("input[name='alergies_reactions[]']").each(function() {
            var value = $(this).val();
            if(value.trim()){
                alergies_reactions.push(value.trim());
            }
        });

        if(alergies_reactions.length === 0) {
            var allergies = true;
        }else{
            var allergies = false;
        }

        if($('body').find('#any_alergies:checkbox:checked').length == 0 && allergies==true){
            allergies = false;
        }else{
            allergies = true;
        }
        /*END  Allergies*/

        /* Previous surgeries*/
        var previous_surgeries = [];
        $('body').find("input[name='previous_surgeries[]']").each(function() {
            var value = $(this).val();
            if(value.trim()){
                previous_surgeries.push(value.trim());
            }
        });

        if(previous_surgeries.length === 0) {
            var surg = true;
        }else{
            var surg = false;
        }

        if($('body').find('#none_prev_surgery:checkbox:checked').length == 0 && surg==true){
            surg = false;
        }else{
            surg = true;
        }
        /*END  Previous surgeries*/

        /* Medical Conditions*/
        var conditions_may_have = [];
        $('body').find("input[name='conditions_may_have[]']").each(function() {
            var value = $(this).val();
            if(value.trim()){
                conditions_may_have.push(value.trim());
            }
        });
        if(conditions_may_have.length === 0) {
            var conditions = true;
        }else{
            var conditions = false;
        }

        if($('body').find('#none_medical_conditions:checkbox:checked').length == 0 && conditions==true){
            snmc = false;
            //$('body').find('.medication_conditions_require').show();
        }else{
            snmc = true;
            //$('body').find('.medication_conditions_require').hide();
        }
        /*END  Medical Conditions*/

        /*Visit Purpose*/
        /*if($('body').find('#visit_purpose').val() == ''){
            svp = false;
            //$('body').find('.visit_purpose_require').show();
        }else{
            svp = true;
            //$('body').find('.visit_purpose_require').hide();
        }*/
        /*End Visit Purpose*/

        /*if(spc==true && spv==true && sut==true && snmct==true && snmc==true && svp==true){*/
            var url = $('#updatePatientForm').attr('action');
            if(flag==true){
                $('.checks-spc').removeClass('redErrorBorder');
                $('.checks-spv').removeClass('redErrorBorder');
                $('.checks-sut').removeClass('redErrorBorder');
                $('.checks-snmct').removeClass('redErrorBorder');
                $('.checks-surg').removeClass('redErrorBorder');
                $('.checks-allergies').removeClass('redErrorBorder');
                $('.checks-snmc').removeClass('redErrorBorder');
                $('.form_data-first_name').removeClass('redErrorBorder');
                $('.form_data-email').removeClass('redErrorBorder');
                $('.form_data-username').removeClass('redErrorBorder');
                $('.form_data-last_name').removeClass('redErrorBorder');
                $('.form_data-birth_month').removeClass('redErrorBorder');
                $('.form_data-birth_day').removeClass('redErrorBorder');
                $('.form_data-birth_year').removeClass('redErrorBorder');
                $('.form_data-gender').removeClass('redErrorBorder');
                $('.form_data-phone1').removeClass('redErrorBorder');
                $('.form_data-email').removeClass('redErrorBorder');
                $('.form_data-pin_code').removeClass('redErrorBorder');
                $('.form_data-street').removeClass('redErrorBorder');
                $('.form_data-city').removeClass('redErrorBorder');
                $('.form_data-state').removeClass('redErrorBorder');
                $('.form_data-zip').removeClass('redErrorBorder');
                $('.form_data-emergency_contact_name').removeClass('redErrorBorder');
                $('.form_data-emergency_contact_relationship').removeClass('redErrorBorder');
                $('.form_data-emergency_contact_phone').removeClass('redErrorBorder');
                $('.form_data-referral_source').removeClass('redErrorBorder');
                $('.form_data-initial-0').removeClass('redErrorBorder');
                $('.form_data-initial-1').removeClass('redErrorBorder');
                $('.form_data-initial-2').removeClass('redErrorBorder');
                $('.form_data-initial-3').removeClass('redErrorBorder');

                $('.checks-spc-message').html('').removeClass('alert alert-danger scroll');
                $('.checks-spv-message').html('').removeClass('alert alert-danger scroll');
                $('.checks-sut-message').html('').removeClass('alert alert-danger scroll');
                $('.checks-allergies-message').html('').removeClass('alert alert-danger scroll');
                $('.checks-snmct-message').html('').removeClass('alert alert-danger scroll');
                $('.checks-surg-message').html('').removeClass('alert alert-danger scroll');
                $('.checks-snmc-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-first_name-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-email-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-username-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-last_name-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-birth_month-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-birth_day-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-birth_year-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-gender-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-phone1-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-email-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-username-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-pin_code-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-street-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-city-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-state-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-zip-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-emergency_contact_name-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-emergency_contact_relationship-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-emergency_contact_phone-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-referral_source-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-referral_source-response-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-initial-0-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-initial-1-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-initial-2-message').html('').removeClass('alert alert-danger scroll');
                $('.form_data-initial-3-message').html('').removeClass('alert alert-danger scroll');

                $('.form_data-initial-0').addClass('fieldsetborder');
                $('.checks-spc').addClass('fieldsetborder');
                $('.checks-spv').addClass('fieldsetborder');
                $('.checks-sut').addClass('fieldsetborder');
                $('.checks-snmct').addClass('fieldsetborder');
                $('.checks-surg').addClass('fieldsetborder');
                $('.checks-alergies').addClass('fieldsetborder');
                $('.checks-snmc').addClass('fieldsetborder');

                $('body').find('.submit-form-patient').attr("disabled", true);
                flag = false;
                $.ajax({
                    type: "POST",
                    url: url,
                    data: { 
                        form_data: $("#updatePatientForm").serialize(),
                        checks: {
                            spc: spc,
                            spv: spv,
                            sut: sut,
                            snmct: snmct,
                            snmc: snmc,
                            allergies: allergies,
                            surg: surg,
                            /*svp: svp,*/
                        },
                        _token: $('[name="_token"]').val()
                    },
                    success: function(data)
                    {   
                        if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                            window.location = data.redirect;
                            return;
                        }

                        if(typeof data.redirect!=='undefined'){
                            window.location = data.redirect;
                        }else{
                            $('body').find('.submit-form-patient').attr("disabled", false);
                            $('#server-message').html('').html(data.html);

                            $('body').animate({ scrollTop: 0 }, 'slow');
                            if(data.success==false){
                                for (var i = 0; i < data.errors.length; i++) {
                                    $('.'+data.errors[i]['key']+'-message').html(data.errors[i]['error']).addClass('alert alert-danger scroll');
                                }

                                var top = $('.scroll').offset().top
                                $('html, body').animate({
                                    scrollTop: top
                                }, 1000);
                            } else if (data.success =='only_1') {
                                var top = $('body').offset().top
                                $('html, body').animate({
                                    scrollTop: top
                                }, 1000);
                            }else{
                                $('body').find('#patient_register_step').val(2);
                                $('body').find('#patient_authorisation_wrap').show();
                                $('body').find('#patient_data_wrap').hide();
                                $('html, body').animate({
                                    scrollTop: 0
                                }, 1000);
                            }
                        }
                        flag = true;
                    }
                });
            }
        /*}else{
            return false;
        }*/
        
    });

    /*Patient List Files Upload*/
/*    $('.OpenFileUpload').on('click','label',function(){ 
        $(this).find('.fileupload').trigger('click'); 
    });

    $('body').on('change','input:file',function(e){ 
        var iduser = $(this).data('iduser');
        var idregister = $(this).data('idregister');
        var tab = $(this).data('tab');
        file_id_register = idregister;

        var formData = new FormData();
        formData.append('_token', $("#csrf-token").val());
        formData.append('file', $(this)[0].files[0]);
        formData.append('iduser', iduser);
        formData.append('idregister', idregister);
        formData.append('tab', tab);

        $('#patient-files'+file_id_register).find('.overlay').show();
        $.ajax({
            url: APP_URL+'uploadFile',
            method:'post',
            processData: false,
            data:formData,
            contentType: false,
            cache:false,
            xhr: function() {
                var myXhr = $.ajaxSettings.xhr();
                if(myXhr.upload){
                    myXhr.upload.addEventListener('progress',progress, false);
                }
                return myXhr;
            },
        }).done(function(data){
            $('#patient-files'+file_id_register).find('.overlay').hide();
            $('#progress'+file_id_register).hide();
            setTimeout(function(){
                $('.server-message-upload'+idregister).hide('slow').html('').show();
            },3000);
            $('.server-message-upload'+idregister).show().html(data.message);

            $('#files-listing-'+tab+'-'+idregister).html('').html(data.html);

        });
    });

    function progress(e){
        if(e.lengthComputable){
            var max = e.total;
            var current = e.loaded;

            var Percentage = (current * 100)/max;

            $('#progress'+file_id_register).show();
            $('#progress'+file_id_register).find('.progress-bar').width(Percentage+'%');

            if(Percentage >= 100)
            {
               return true;
            }
        }  
     }
*/

    $('body').on('click','.uploadFileFromModal',function(e){ 
        var iduser = $(this).data('iduser');
        var idregister = $(this).data('idregister');
        var tab = $(this).data('tab');
        var rename = ''; /*$('#rename').val();*/
        var chooseTab = $(this).data('choosetab');
        var isPls = $("input[name='isPls']:checked").val();
        var plsid = $(this).data('plsid');
        var idRow = $(this).data('idrow');
        var idConsent = $(this).data('idconsent');
        var uploadTab = $("input[name='upload_tab']:checked").val();

        var selected = new Array();
        $("input[name='id_company[]']:checked").map(function(){
            console.log(this.value)
            selected.push(this.value);
        });
        var id_company = selected
        file_id_register = idregister;

        var formData = new FormData();
        formData.append('_token', $("#_token").val());
        formData.append('file', $('#file')[0].files[0]);
        formData.append('iduser', iduser);
        formData.append('idregister', idregister);
        formData.append('tab', tab);
        formData.append('rename', rename);
        formData.append('chooseTab', chooseTab);
        formData.append('uploadTab', uploadTab);
        formData.append('isPls', isPls);
        formData.append('idRow', idRow);
        formData.append('idConsent', idConsent);
        formData.append('id_company', id_company);
        formData.append('plsid', plsid);


        $('#patient-files'+file_id_register).find('.overlay').show();
        $('body').find('.uploadFileFromModal').attr('disabled',true);

        $.ajax({
            url: APP_URL+'uploadFile',
            method:'post',
            processData: false,
            data:formData,
            contentType: false,
            cache:false,
            xhr: function() {
                var myXhr = $.ajaxSettings.xhr();
                if(myXhr.upload){
                    myXhr.upload.addEventListener('progress',progress, false);
                }
                return myXhr;
            },
        }).done(function(data){
            if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                window.location = data.redirect;
                return;
            }

            $('body').find('.uploadFileFromModal').attr('disabled',false);
            $('body').find('#uploadFileModal').modal('toggle');
            $('#patient-files'+file_id_register).find('.overlay').hide();
            $('#progress'+file_id_register).hide();
            setTimeout(function(){
                $('.server-message-upload'+idregister).hide('slow').html('').show();
            },3000);
            $('.server-message-upload'+idregister).show().html(data.message);

            $('#files-listing-'+tab+'-'+idregister).html('').html(data.html);
            $('#files-listing-company-'+idregister).html('').html(data.companyFilesHtml);
            
            $('body').find('.row_collapse_'+idregister).click();
            $('#file_tab_click').val(tab+'_'+idregister)
            setTimeout( function (){
                $('body').find('.row_collapse_'+idregister).click();
            }
            , 750);

            /*PLS*/
            if(isPls==true){
                $('body').find('.'+iduser+idRow+idConsent).css("color", "#41d441");
                $('body').find('.rowid_'+idConsent+'_'+idRow).addClass("green-pls");
            }
            $('#server-message').show().html(data.message);
            $('#pls_progress').hide();
        });
    });

    function progress(e){
        if(e.lengthComputable){
            var max = e.total;
            var current = e.loaded;

            var Percentage = (current * 100)/max;

            $('#progress'+file_id_register).show();
            $('#progress'+file_id_register).find('.progress-bar').width(Percentage+'%');

            $('#pls_progress').show();
            $('#pls_progress').find('.progress-bar').width(Percentage+'%');

            if(Percentage >= 100)
            {   
                return true;
            }
        }  
    }








    $('body').on('click', '.getTestsCheck', function (event) {
        var iduser = $(this).data('iduser');
        var idConsent = $(this).data('idconsent');
        var id_row = $(this).data('idrow');
        
        if (flag == true) {
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL + 'getTestsCheck',
                data: {
                    iduser: iduser,
                    idConsent: idConsent,
                    id_row: id_row,
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                        window.location = data.redirect;
                        return;
                    }
                    var html = '';
                    data.data.forEach(function (element) {
                        if (element.row!=id_row){
                            return;
                        }
                        var checked = ''
                        if (element.pls_check_test == 1) {
                            checked = 'checked disabled'
                        }
                        html = html + '<li><input type="checkbox" ' + checked+' name="test_to_check" id="' + element.id + '" value="' + element.id + '"><label class="form-check-label" for="' + element.id + '">' + element.pls_short_name + '</label></li>';
                    });
                    $('#test_check_idconsent').val(idConsent)
                    $('#test_check_idrow').val(id_row)
                    $('.testsCheckModalBody  .tests-check-list').html(html);
                }
            });
            flag = true;
        }
    });

    $('body').on('click', '.saveCheckTests', function (event) {
        var idconsent = $('#test_check_idconsent').val();
        var id_row = $('#test_check_idrow').val();

        var ids = [];
        $.each($("input[name='test_to_check']:checked"), function () {
            ids.push($(this).val());
        });

        if (flag == true) {
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL + 'saveCheckTests',
                data: {
                    ids: JSON.stringify(ids),
                    idconsent: idconsent,
                    id_row: id_row,
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                        window.location = data.redirect;
                        return;
                    }

                    if (data.success == true) {
                        location.reload()
                    }
                }
            });
            flag = true;
        }
    });













    $('body').on('click', '.getServicesToDelete', function (event) {
        var iduser = $(this).data('iduser');
        var idConsent = $(this).data('idconsent');

        if (flag == true) {
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL + 'getServicesToDelete',
                data: {
                    iduser: iduser,
                    idConsent: idConsent,
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                        window.location = data.redirect;
                        return;
                    }
                    var html = '';
                    data.data.forEach(function(element) {
                        html = html + '<li><input type="checkbox" name="item_to_delete" id="' + element.id + '" value="' + element.id + '"><label class="form-check-label" for="' + element.id + '">' + element.pls_short_name + '</label></li>';
                    });
                    $('#service_delete_idconsent').val(idConsent)
                    $('.servicesDeleteBody  .services-list').html(html);
                }
            });
            flag = true;
        }
    });

    $('body').on('click', '.deleteServicesModal', function (event) {
        var idconsent = $('#service_delete_idconsent').val();

        var ids = [];
        $.each($("input[name='item_to_delete']:checked"), function () {
            ids.push($(this).val());
        });

        if (flag == true) {
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL + 'deleteServicesModal',
                data: {
                    ids: JSON.stringify(ids),
                    idconsent: idconsent,
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                        window.location = data.redirect;
                        return;
                    }
                   
                    if(data.success==true) {
                        location.reload()
                    }
                }
            });
            flag = true;
        }
    });

    $('body').on('click','.OpenFileUpload', function(event) {
        var iduser = $(this).data('iduser');
        var idregister = $(this).data('idregister');
        var tab = $(this).data('tab');
        var chooseTab = $(this).data('choosetab');
        var isPls = $(this).data('ispls');
        var plsid = $(this).data('plsid');
        var idRow = $(this).data('idrow');
        var idConsent = $(this).data('idconsent');

        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'fileUploadModal',
                data: { 
                    iduser: iduser,
                    idregister: idregister,
                    tab: tab,
                    chooseTab: chooseTab,
                    isPls: isPls,
                    idRow: idRow,
                    idConsent: idConsent,
                    plsid: plsid,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('#uploadFileModal div.modal-body').html(data.html);
                }
            });
            flag = true;
        }
    });

    $('body').on('click','.file-delete', function(e){
        if (confirm('Are you sure you want to delete this?')) {
            var idfile = $(this).data('idfile');
            var iduser = $(this).data('iduser');
            var idregister = $(this).data('idregister');
            var tab = $(this).data('tab');

            $.ajax({
                url: APP_URL+'deleteFile',
                method:'post',
                data:{
                    id_file: idfile,
                    id_user: iduser,
                    id_register: idregister,
                    tab: tab,
                    _token: $('[name="_token"]').val()
                },
            }).done(function(data){
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                setTimeout(function(){
                    $('.server-message-upload'+idregister).hide('slow').html('').show();
                },3000);
                $('.server-message-upload'+idregister).show().html(data.message);

                $('body').find('#files-listing-'+tab+'-'+idregister).html('').html(data.html);
                $('#files-listing-company-' + idregister).html('').html(data.companyFilesHtml);

            });
        }
    });
    /*END Patient List Files Upload*/

    /*Consent Code*/
    $("#patients_list").on('click','.consentModal', function (e) {
        var twoOrMore = $('#twoOrMore').val();

        var id_visit_purpose = $(this).data('visitpurpose');
        var id_user = $(this).data('iduser');
        var id_register = $(this).data('idregister');

        if (twoOrMore == 1) {
            if (confirm('Are you sure this is the correct patient, first name, middle name if available, last name, date of birth?')) {
                consentModalF(id_visit_purpose, id_user, id_register)
            }else {
                $('body').find('#servicesModal').modal('toggle');
            }
        } else {
            consentModalF(id_visit_purpose, id_user, id_register)
        }
    });

    function consentModalF(id_visit_purpose, id_user, id_register) {
        $.ajax({
            url: APP_URL + 'consentModal',
            method: "POST",
            data: {
                id_user: id_user,
                id_register: id_register,
                id_visit_purpose: id_visit_purpose,
                _token: $('[name="_token"]').val()
            },
            dataType: "json"
        }).done(function (data, statusText, xhr) {
            if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                window.location = data.redirect;
                return;
            }

            $('#servicesModal div.modal-body').html(data.html);
            $('#servicesModal').css("overflow-x", "scroll");
        }).fail(function () {
            /*alert( "error" ); */
        });
    }

    $("body").on('click','.consent-save', function (e) {
        var id_user = $("body").find('#consent #id_user').val();
        var id_register = $("body").find('#consent #id_register').val();
        var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
        
        var ticket = $("body").find('#consent #ticket').val();
        var ticket_matches = $("body").find('#consent #ticket_matches').val();
        var initial_one = $("body").find('#consent #initial_one').val();
        var initial_two = $("body").find('#consent #initial_two').val();

        var n = initial_one.includes("/");

        if(n==false) {
            initial_one = initial_one+'/'
        }

        $('.consent-save').attr('disabled',true);

        if(flag==true){
            flag = false;
            $.ajax({
                url: APP_URL+'consentSave',
                method: "POST",
                data: { 
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    ticket: ticket,
                    ticket_matches: ticket_matches,
                    initial_one: initial_one,
                    initial_two: initial_two,
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                $('.consent-save').attr('disabled',false);
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }


                $('body').find('.consentVaccineAuthorisationModalBody').html('');
                if (data.hasLatexVaccine=='true') {
                    if (typeof (data.modalApprove) != "undefined" && (typeof id_clinician == "undefined" || id_clinician == '')) {
                        setTimeout(function () {
                            $("#consentVaccineAuthorisationModal").modal({
                                backdrop: 'static',
                                keyboard: false
                            });
                        }, 1000);


                        $('body').find('.consentVaccineAuthorisationModalBody').html('').html(data.modalApprove);
                        return;
                    }
                }


                setTimeout(function(){
                    $('#server-message-consent').hide('slow').html('').show();
                },3000);
                $('#server-message-consent').show().html('').html(data.message);
            }).fail(function() {
                /*alert( "error" ); */
            });
            flag = true;
        }
    });

    $("body").on('change','#consent_select_vaccine', function (e) {
        var id_vaccine = $("body").find('#consent_select_vaccine').val();
        if(flag==true){
            flag = false;
            $.ajax({
                url: APP_URL+'getVaccineDMR',
                method: "POST",
                data: { 
                    id_vaccine: id_vaccine,
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }
                $("body").find('#add_vaccine_manufact_name').val(data.data.v_manufact);
                $("body").find('#add_vaccine_route').val(data.data.v_route);
            }).fail(function() {

            });
            flag=true;
        }
    })


    $("body").on('change','.edit_consent_select_vaccine', function (e) {
        var id_vaccine = $("body").find('.edit_consent_select_vaccine').val();
        if(flag==true){
            flag = false;
            $.ajax({
                url: APP_URL+'getVaccineDMR',
                method: "POST",
                data: { 
                    id_vaccine: id_vaccine,
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }
                $("body").find('.edit_vaccine_manufact_name').val(data.data.v_manufact);
                $("body").find('.edit_vaccine_route').val(data.data.v_route);
            }).fail(function() {

            });
            flag=true;
        }
    })

    $("body").on('click', '#select_clinician_modal_consent_os', function(e) {
        var id_clinician = $('#list_clinician_modal').val();
        if(id_clinician == "Select"){
            alert('Select a clinician!');
        }else{
            $('body').find('.saveToFile').prop('disabled',false);
            $('body').find('.printFile').prop('disabled',false);
            $("body").find('#id_clinician_selected_modal').val(id_clinician);
            $("body").find('#id_clinician_safety_consent').val(id_clinician);
            $('body').find('.modalXClose10').click();

            //$('body').find('.add-other-service').attr('disabled',false).click(); //consent
        }
    })

     $("body").on('click', '#select_clinician_modal_consent', function(e) {
        var id_clinician = $('#list_clinician_modal').val();
        if(id_clinician == "Select"){
            alert('Select a clinician!');
        }else{
            $("body").find('#id_clinician_safety_consent').val(id_clinician);
            $('body').find('.modalXClose10').click();
            var clinician_safety_reason = $('body').find('#clinician_safety_reason').val();
            $('body').find('.clinician_safety_reason').val(clinician_safety_reason);

            $('body').find('.signSubmit').attr('disabled',false).click(); //consent
        }
    })

    $("body").on('click', '#select_clinician_modal', function(e) {
        var id_clinician = $('#list_clinician_modal').val();
        if(id_clinician == "Select"){
            alert('Select a clinician!');
        }else{
            $("body").find('#id_clinician_selected_modal').val(id_clinician);
            $("body").find('#id_clinician_safety_consent').val(id_clinician);
            $('body').find('.modalXClose10').click();

            var visitpurpose = $("body").find("#id_visit_purpose_clinician_select_modal").val();
            $("body").find('#add_form_'+visitpurpose).click();
            $("body").find('.add-vaccine').click();
        }
    })


    $("body").on('click','.add-vaccine', function (e) {
        var vp_date_add = $('body').find('#vp_date_add').val()
        var consent_user_type = $('body').find('#consent_user_type').val()

        let current_datetime = new Date()
        let js_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate()

        if (js_date != vp_date_add && consent_user_type=='admin') {
            if (!confirm('You are attempting to add services to a previous date. Create a new visit for services if needed')) {
                return;
            }
        }


        var id_user = $("body").find('#consent #id_user').val();
        var id_register = $("body").find('#consent #id_register').val();
        var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
        var consent_select_vaccine = $("body").find('#consent_select_vaccine').val();
        var vaccine_dose = $("body").find('#add_vaccine_dose').val();
        var vaccine_manufact_name = $("body").find('#add_vaccine_manufact_name').val();
        var vaccine_lot = $("body").find('#add_vaccine_lot').val();
        var vaccine_exp_date = $("body").find('#add_vaccine_exp_date').val();
        var vaccine_date = $("body").find('#add_vaccine_date').val();
        var vaccine_site = $("body").find('#add_vaccine_site').val();
        var vaccine_route = $("body").find('#add_vaccine_route').val();
        var vaccine_initial = $("body").find('#add_vaccine_initial').val();
        var vaccine_position = $("body").find('#add_vaccine_position').val();
        var id_clinician = $("body").find('#id_clinician_selected_modal').val();
        var clinician_safety_reason = $("body").find('#clinician_safety_reason').val();

        var date = new Date();
        date = date.setHours(0,0,0,0);
        //var date = getTodayDate(date);
        vaccine_exp_date_time = new Date(vaccine_exp_date);

        if(vaccine_exp_date_time.getTime() < date && vaccine_exp_date_time.getTime()!=''){
            setTimeout(function(){
                $("#formValidityModal").modal({
                    backdrop: 'static',
                    keyboard: false
                });
                $("body").find('#add_vaccine_exp_date').val('');
            },1000);

            $('body').find('.formValidityModalBody').html('').html(
                '<div style="    color: red;font-size: 33px;text-align: center;padding: 30px;">This vaccine is expired, DO NOT ADMINISTER!<br>Remove this vaccine immediately from the fridge/freezer and give to the Clinician!</div>'
            );
            return;
        }

        $('.add-vaccine').attr('disabled',true);
        
        if(flag==true){
            flag = false;
            if(vaccine_dose!='' && vaccine_manufact_name!='' && vaccine_lot!='' && vaccine_exp_date!= '' && vaccine_date!='' && vaccine_site!='' && vaccine_route!='' && vaccine_initial!='' && vaccine_position!=''){
                $('body').find('.full_overlay p').html('');
                $('body').find('.full_overlay').show();
            }
            $.ajax({
                url: APP_URL+'consentSaveVaccine',
                method: "POST",
                data: { 
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    consent_select_vaccine: consent_select_vaccine,
                    vaccine_dose: vaccine_dose,
                    vaccine_manufact_name: vaccine_manufact_name,
                    vaccine_lot: vaccine_lot,
                    vaccine_exp_date: vaccine_exp_date,
                    vaccine_date: vaccine_date,
                    vaccine_site: vaccine_site,
                    vaccine_route: vaccine_route,
                    vaccine_initial: vaccine_initial,
                    vaccine_position: vaccine_position,
                    id_clinician_safety: id_clinician,
                    clinician_safety_reason: clinician_safety_reason,
                    action: 'save',
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                $('.add-vaccine').attr('disabled',false);
                $('body').find('.full_overlay').hide();
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                if(typeof(data.alert) != "undefined"){
                    alert(data.alert);
                    return;
                }


                $('body').find('.consentVaccineAuthorisationModalBody').html('');
                if(typeof(data.modalApprove) != "undefined" && (typeof id_clinician == "undefined" || id_clinician == '')){
                    setTimeout(function(){
                        $("#consentVaccineAuthorisationModal").modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                    },1000);


                    $('body').find('.consentVaccineAuthorisationModalBody').html('').html(data.modalApprove);
                    return;
                }


                setTimeout(function(){
                    $('body').find('.row_collapse_'+id_register).click();
                    $('#server-message-vaccines').hide('slow').html('').show();
                },5000);

                if(vaccine_dose!='' && vaccine_manufact_name!='' && vaccine_lot!='' && vaccine_exp_date!= '' && vaccine_date!='' && vaccine_site!='' && vaccine_route!='' && vaccine_initial!='' && vaccine_position!='')

                $('body').find('.row_collapse_'+id_register).click();
                if(data.success==false){
                    $('#server-message-vaccines').show().html('').html(data.message);
                }else{
                    $("body").find('#consent_select_vaccine').prop('selectedIndex',0);
                    $("body").find('#add_vaccine_dose').val('');
                    $("body").find('#add_vaccine_manufact_name').val('');
                    $("body").find('#add_vaccine_lot').val('');
                    $("body").find('#add_vaccine_exp_date').val('');
                    /*$("body").find('#add_vaccine_date').val('');*/
                    $("body").find('#add_vaccine_site').val('');
                    $("body").find('#add_vaccine_initial').val('');
                    $("body").find('#add_vaccine_position').val('');

                    $('#server-message-vaccines').show().html('').html(data.message);
                    $('#vaccines-body').html('').html(data.html);

                    if(data.fetch_ehr == true){
                        $('#getPatientDataFromWebIZ').attr('data-makepdf','1').delay(500).click();
                    }
                }
                $('body').find('.row_collapse_'+id_register).click();
            }).fail(function() {
                $('.add-vaccine').attr('disabled',false);
            });
            flag = true;
        }
    });

    $("body").on('change','#consent_select_prescription', function (e) {
        var prescr = $("#consent_select_prescription option:selected").text();
        $("body").find('#consent_prescription_detail').val(prescr);
    });

    $("body").on('change','.edit_consent_select_prescription', function (e) {
        var prescr = $(".edit_consent_select_prescription option:selected").text();
        $("body").find('.edit_consent_prescription_detail').val(prescr);
    });

    $("body").on('click','.add-prescription', function (e) {
        var vp_date_add = $('body').find('#vp_date_add').val()
        var consent_user_type = $('body').find('#consent_user_type').val()
        let current_datetime = new Date()
        let js_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate()

        console.log(js_date, vp_date_add);
        if (js_date != vp_date_add && consent_user_type == 'admin') {
            if (!confirm('You are attempting to add services to a previous date. Create a new visit for services if needed')) {
                return;
            }
        }

        var id_user = $("body").find('#consent #id_user').val();
        var id_register = $("body").find('#consent #id_register').val();
        var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
        var consent_select_prescription = $("body").find('#consent_select_prescription').val();
        var consent_prescription_detail = $("body").find('#consent_prescription_detail').val();
        var consent_doctor_prescription = $("body").find('#consent_doctor_prescription').val();
        var refill = $("body").find('#consent_refill_prescription').val();
        var substitution = $("body").find('#consent_substitution_prescription').val();
        var pill = $("body").find('#add_prescription_pill').val();
        var administration_form = $("body").find('#add_administration_form').val();
        var date = $("body").find('#add_prescription_date').val();

        $('.add-prescription').attr('disabled',true);

        if(flag==true){
            flag = false;
            $.ajax({
                url: APP_URL+'consentSavePrescription',
                method: "POST",
                data: { 
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    consent_select_prescription: consent_select_prescription,
                    consent_prescription_detail: consent_prescription_detail,
                    id_doctor: consent_doctor_prescription,
                    refill: refill,
                    substitution: substitution,
                    pill: pill,
                    administration_form: administration_form,
                    date: date,
                    action: 'save',
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                $('.add-prescription').attr('disabled',false);
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                setTimeout(function(){
                    $('#server-message-prescription').hide('slow').html('').show();
                },3000);
                if(data.success==false){
                    $('#server-message-prescription').show().html('').html(data.message);
                }else{
                    $("body").find('#consent_select_prescription').prop('selectedIndex',0);
                    $("body").find('#consent_doctor_prescription').prop('selectedIndex',0);
                    $("body").find('#consent_refill_prescription').prop('selectedIndex',0);
                    $("body").find('#consent_substitution_prescription').prop('selectedIndex',0);
                    $("body").find('#add_prescription_pill').val('');
                    $("body").find('#consent_prescription_detail').val('');

                    $('#server-message-prescription').show().html('').html(data.message);
                    $('#prescription-body').html('').html(data.html);
                }
            }).fail(function() {
                $('.add-prescription').attr('disabled',false);
            });
            flag = true;
        }
    });

    $("body").on('click','.add-other-service', function (e) {
        var vp_date_add = $('body').find('#vp_date_add').val()
        var consent_user_type = $('body').find('#consent_user_type').val()
        let current_datetime = new Date()
        let js_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate()

        if (js_date != vp_date_add && consent_user_type == 'admin') {
            if (!confirm('You are attempting to add services to a previous date. Create a new visit for services if needed')) {
                return;
            }
        }

        var id_user = $("body").find('#consent #id_user').val();
        var id_register = $("body").find('#consent #id_register').val();
        var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
        var consent_other_service = $('#other-service-main-hidden').val();
        var consent_other_service_doctor = $('#consent_doctor_other-services').val();
        var consent_other_service_quantity = $('#other-services-quantity').val();
        var consent_other_service_row = $('#other-services-row').val();
        var consent_other_service_date = $('#add_other-services_date').val();
        var id_clinician = $("body").find('#id_clinician_selected_modal').val();
        var clinician_safety_reason = $("body").find('#clinician_safety_reason').val();

        $('.add-other-service').attr('disabled',true);

        if(flag==true){
            flag = false;
            $.ajax({
                url: APP_URL+'consentSaveOtherService',
                method: "POST",
                data: { 
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    other_service: consent_other_service,
                    id_doctor: consent_other_service_doctor,
                    quantity: consent_other_service_quantity,
                    row: consent_other_service_row,
                    date: consent_other_service_date,
                    id_clinician_safety: id_clinician,
                    clinician_safety_reason: clinician_safety_reason,
                    action: 'save',
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                $('.add-other-service').attr('disabled',false);
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                if (typeof (data.alert) != "undefined") {
                    alert(data.alert);
                    return;
                }

                $('body').find('.consentVaccineAuthorisationModalBody').html('');
                if(typeof(data.modalApprove) != "undefined" && (typeof id_clinician == "undefined" || id_clinician == '')){
                    setTimeout(function(){
                        $("#consentVaccineAuthorisationModal").modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                    },1000);


                    $('body').find('.consentVaccineAuthorisationModalBody').html('').html(data.modalApprove);
                    return;
                }

                setTimeout(function(){
                    $('body').find('.row_collapse_'+id_register).click();
                    $('#server-message-other-services').hide('slow').html('').show();
                },3000);
                if(data.success==false){
                    $('#server-message-other-services').show().html('').html(data.message);
                }else{
                    var items = data.selected_items_to_pls;
                    $("#other-service").empty();
                    $('body').find("#other-services").empty();
                    $("body").find('#other-service-main').val('');
                    $("body").find('#other-service-main-hidden').val('');
                    $("body").find('#consent_doctor_other-services').prop('selectedIndex',0);
                    $("body").find('#other-services-quantity').val('1');
                    //$("body").find('#other-services-row').val('1');
                    /*$("body").find('#add_other-services_date').val('');*/

                    $('#server-message-other-services').show().html('').html(data.message);
                    $('#other-services-body').html('').html(data.html);

                    $('#consent_doctor_other-services').hide();

                    //row up
                    var next_row = parseInt(consent_other_service_row)+1;
                    $('#other-services-row').val(next_row).change();
                    //

                    if(data.pop_modal==true &&  items.indexOf(parseInt(consent_other_service))>0)
                        $('#modalSpreadsheet_'+data.id_consent).click()
                }

                $('body').find('.row_collapse_'+id_register).click();
            }).fail(function() {
                $('.add-other-service').attr('disabled',false);
            });
            flag = true;
        }
    });



    $("body").on('click','.delete-vaccine', function (e) {
        if (confirm('Are you sure you want to delete this?')) {
            var id = $(this).data('id');
            var id_user = $("body").find('#consent #id_user').val();
            var id_register = $("body").find('#consent #id_register').val();
            var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
            
            $.ajax({
                url: APP_URL+'consentDeleteVaccine',
                method: "POST",
                data: { 
                    id: id,
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                setTimeout(function(){
                    $('#server-message-vaccines').hide('slow').html('').show();
                },3000);
                if(data.success==false){
                    $('#server-message-vaccines').show().html('').html(data.message);
                }else{
                    $('#server-message-vaccines').show().html('').html(data.message);
                    $('#vaccines-body').html('').html(data.html);
                }
            }).fail(function() {

            });
        }
    });

    $("body").on('click','.delete-prescription', function (e) {
        if (confirm('Are you sure you want to delete this?')) {
            var id = $(this).data('id');
            var id_user = $("body").find('#consent #id_user').val();
            var id_register = $("body").find('#consent #id_register').val();
            var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
            
            $.ajax({
                url: APP_URL+'consentDeletePrescription',
                method: "POST",
                data: { 
                    id: id,
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                setTimeout(function(){
                    $('#server-message-prescription').hide('slow').html('').show();
                },3000);
                if(data.success==false){
                    $('#server-message-prescription').show().html('').html(data.message);
                }else{
                    $('#server-message-prescription').show().html('').html(data.message);
                    $('#prescription-body').html('').html(data.html);
                }
            }).fail(function() {

            });
        }
    });

    $("body").on('click','.delete-other-service', function (e) {
        if (confirm('Are you sure you want to delete this?')) {
            var id = $(this).data('id');
            var id_user = $("body").find('#consent #id_user').val();
            var id_register = $("body").find('#consent #id_register').val();
            var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
            
            $.ajax({
                url: APP_URL+'consentDeleteOtherService',
                method: "POST",
                data: { 
                    id: id,
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }
                

                setTimeout(function(){
                    $('#server-message-other-services').hide('slow').html('').show();
                    $('body').find('.row_collapse_'+id_register).click();
                },3000);
                if(data.success==false){
                    $('#server-message-other-services').show().html('').html(data.message);
                }else{
                    $('#server-message-other-services').show().html('').html(data.message);
                    $('#other-services-body').html('').html(data.html);

                    var count = parseInt(data.count_os)+parseInt(data.count_cpl)+1;
                    $("body").find("#other-services-row").val(count).change();
                }
            }).fail(function() {

            });
        }
    });

    $("body").on('click','.edit-vaccine', function (e) {
        var id = $(this).data('id');
        $('#vaccines-body #'+id+' .show-td').hide();
        $('#vaccines-body #'+id+' .edit-td').show();

        $('#vaccines-body #'+id+' .delete-vaccine').hide();
        $('#vaccines-body #'+id+' .edit-vaccine').hide();
        $('#vaccines-body #'+id+' .save-vaccine').show();
        $('#vaccines-body #'+id+' .cancel-vaccine').show();

        $('#consent-vaccines-table thead select,#consent-vaccines-table thead input,#consent-vaccines-table thead button').hide();
        $('#consent-vaccines-table .edit-vaccine').attr('disabled',true);
    });
    $("body").on('click','.cancel-vaccine', function (e) {
        var id = $(this).data('id');
        $('#vaccines-body #'+id+' .show-td').show();
        $('#vaccines-body #'+id+' .edit-td').hide();

        $('#vaccines-body #'+id+' .delete-vaccine').show();
        $('#vaccines-body #'+id+' .edit-vaccine').show();
        $('#vaccines-body #'+id+' .save-vaccine').hide();
        $('#vaccines-body #'+id+' .cancel-vaccine').hide();

        $('#consent-vaccines-table thead select,#consent-vaccines-table thead input,#consent-vaccines-table thead button').show();
        $('#consent-vaccines-table .edit-vaccine').attr('disabled',false);
    });
    $("body").on('click','.save-vaccine', function (e) {
        var id = $(this).data('id');
        var id_user = $("body").find('#consent #id_user').val();
        var id_register = $("body").find('#consent #id_register').val();
        var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
        var consent_select_vaccine = $('#vaccines-body #'+id+' .edit_consent_select_vaccine').val();
        var vaccine_dose = $('#vaccines-body #'+id+' .edit_vaccine_dose').val();
        var vaccine_manufact_name = $('#vaccines-body #'+id+' .edit_vaccine_manufact_name').val();
        var vaccine_lot = $('#vaccines-body #'+id+' .edit_vaccine_lot').val();
        var vaccine_exp_date = $('#vaccines-body #'+id+' .edit_vaccine_exp_date').val();
        var hidden_edit_vaccine_exp_date = $('#vaccines-body #'+id+' .hidden_edit_vaccine_exp_date').val();
        var vaccine_date = $('#vaccines-body #'+id+' .edit_vaccine_date').val();
        var vaccine_site = $('#vaccines-body #'+id+' .edit_vaccine_site').val();
        var vaccine_route = $('#vaccines-body #'+id+' .edit_vaccine_route').val();
        var vaccine_initial = $('#vaccines-body #'+id+' .edit_vaccine_initial').val();
        var vaccine_position = $('#vaccines-body #'+id+' .edit_vaccine_position').val();

        var date = new Date();
        date = date.setHours(0,0,0,0);
        vaccine_exp_date_time = new Date(vaccine_exp_date);

        if(vaccine_exp_date_time.getTime() < date && vaccine_exp_date != hidden_edit_vaccine_exp_date && vaccine_exp_date!=''){
            setTimeout(function(){
                $("#formValidityModal").modal({
                    backdrop: 'static',
                    keyboard: false
                });
                $('#vaccines-body #'+id+' .edit_vaccine_exp_date').val('');
            },1000);

            $('body').find('.formValidityModalBody').html('').html(
                '<div style="    color: red;font-size: 33px;text-align: center;padding: 30px;">This vaccine is expired, DO NOT ADMINISTER!<br>Remove this vaccine immediately from the fridge/freezer and give to the Clinician!</div>'
            );
            return;
        }

        $('.save-vaccine').attr('disabled',true);

        if(flag==true){
            flag = false;
            if(vaccine_dose!='' && vaccine_manufact_name!='' && vaccine_lot!='' && vaccine_exp_date!= '' && vaccine_date!='' && vaccine_site!='' && vaccine_route!='' && vaccine_initial!='' && vaccine_position!=''){
                $('body').find('.full_overlay p').html('');
                $('body').find('.full_overlay').show();
            }

            $.ajax({
                url: APP_URL+'consentSaveVaccine',
                method: "POST",
                data: { 
                    id: id,
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    consent_select_vaccine: consent_select_vaccine,
                    vaccine_dose: vaccine_dose,
                    vaccine_manufact_name: vaccine_manufact_name,
                    vaccine_lot: vaccine_lot,
                    vaccine_exp_date: vaccine_exp_date,
                    vaccine_date: vaccine_date,
                    vaccine_site: vaccine_site,
                    vaccine_route: vaccine_route,
                    vaccine_initial: vaccine_initial,
                    vaccine_position: vaccine_position,
                    action: 'update',
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                $('.save-vaccine').attr('disabled',false);
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                $('body').find('.consentVaccineAuthorisationModalBody').html('');
                if (typeof (data.modalApprove) != "undefined" && (typeof id_clinician == "undefined" || id_clinician == '')) {
                    setTimeout(function () {
                        $("#consentVaccineAuthorisationModal").modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                    }, 1000);


                    $('body').find('.consentVaccineAuthorisationModalBody').html('').html(data.modalApprove);
                    return;
                }

                if(vaccine_dose!='' && vaccine_manufact_name!='' && vaccine_lot!='' && vaccine_exp_date!= '' && vaccine_date!='' && vaccine_site!='' && vaccine_route!='' && vaccine_initial!='' && vaccine_position!='')
                    //$('body').find('.full_overlay').hide();

                $('#consent-vaccines-table thead select,#consent-vaccines-table thead input,#consent-vaccines-table thead button').show();
                setTimeout(function(){
                    $('#server-message-vaccines').hide('slow').html('').show();
                },3000);
                if(data.success==false){
                    $('#server-message-vaccines').show().html('').html(data.message);
                }else{
                    $('#server-message-vaccines').show().html('').html(data.message);
                    $('#vaccines-body').html('').html(data.html);

                    if(data.fetch_ehr == true){
                        console.log('raducu');
                        $('#getPatientDataFromWebIZ').click();
                    }
                }
            }).fail(function() {
                $('.save-vaccine').attr('disabled',false);
            });
            flag = true;
        }
    });

    $("body").on('click','.edit-prescription', function (e) {
        var id = $(this).data('id');
        $('#prescription-body #'+id+' .show-td').hide();
        $('#prescription-body #'+id+' .edit-td').show();

        $('#prescription-body #'+id+' .delete-prescription').hide();
        $('#prescription-body #'+id+' .edit-prescription').hide();
        $('#prescription-body #'+id+' .save-prescription').show();
        $('#prescription-body #'+id+' .cancel-prescription').show();

        $('#consent-prescription-table thead select,#consent-prescription-table thead input,#consent-prescription-table thead button').hide();
        $('#consent-prescription-table .edit-prescription').attr('disabled',true);
    });
    $("body").on('click','.cancel-prescription', function (e) {
        var id = $(this).data('id');
        $('#prescription-body #'+id+' .show-td').show();
        $('#prescription-body #'+id+' .edit-td').hide();

        $('#prescription-body #'+id+' .delete-prescription').show();
        $('#prescription-body #'+id+' .edit-prescription').show();
        $('#prescription-body #'+id+' .save-prescription').hide();
        $('#prescription-body #'+id+' .cancel-prescription').hide();

        $('#consent-prescription-table thead select,#consent-prescription-table thead input,#consent-prescription-table thead button').show();
        $('#consent-prescription-table .edit-prescription').attr('disabled',false);
    });
    $("body").on('click','.save-prescription', function (e) {
        var id = $(this).data('id');
        var id_user = $("body").find('#consent #id_user').val();
        var id_register = $("body").find('#consent #id_register').val();
        var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
        var consent_select_prescription = $('#prescription-body #'+id+' .edit_consent_select_prescription').val();
        var consent_prescription_detail = $('#prescription-body #'+id+' .edit_consent_prescription_detail').val();
        var consent_doctor_prescription = $('#prescription-body #'+id+' .edit_consent_doctor_prescription').val();
        var consent_refill_prescription = $('#prescription-body #'+id+' .edit_consent_refill_prescription').val();
        var consent_substitution_prescription = $('#prescription-body #'+id+' .edit_consent_substitution_prescription').val();
        var pill = $('#prescription-body #'+id+' .edit_prescription_pill').val();
        var administration_form = $('#prescription-body #'+id+' .edit_administration_form').val();
        var date = $('#prescription-body #'+id+' .edit_prescription_date').val();

        $('.save-prescription').attr('disabled',true);

        if(flag==true){
            flag = false;
            $.ajax({
                url: APP_URL+'consentSavePrescription',
                method: "POST",
                data: { 
                    id: id,
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    consent_select_prescription: consent_select_prescription,
                    consent_prescription_detail: consent_prescription_detail,
                    id_doctor: consent_doctor_prescription,
                    refill: consent_refill_prescription,
                    substitution: consent_substitution_prescription,
                    pill: pill,
                    administration_form: administration_form,
                    date: date,
                    action: 'update',
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                $('.save-prescription').attr('disabled',false);
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                $('#consent-prescription-table thead select,#consent-prescription-table thead input,#consent-prescription-table thead button').show();
                setTimeout(function(){
                    $('#server-message-prescription').hide('slow').html('').show();
                },3000);
                if(data.success==false){
                    $('#server-message-prescription').show().html('').html(data.message);
                }else{
                    $('#server-message-prescription').show().html('').html(data.message);
                    $('#prescription-body').html('').html(data.html);
                }
            }).fail(function() {
                $('.save-prescription').attr('disabled',false);
            });
            flag = true;
        }
    });

    $("body").on('click','.edit-other-service', function (e) {
        var id = $(this).data('id');
        $('#other-services-body #'+id+' .show-td').hide();
        $('#other-services-body #'+id+' .edit-td').show();

        $('#other-services-body #'+id+' .delete-other-service').hide();
        $('#other-services-body #'+id+' .edit-other-service').hide();
        $('#other-services-body #'+id+' .save-other-service').show();
        $('#other-services-body #'+id+' .cancel-other-service').show();

        $('#consent-other-services-table thead #other-service,#consent-other-services-table thead #other-service-main,#consent-other-services-table thead #other-services-quantity,#consent-other-services-table thead button,#consent-other-services-table thead #add_other-services_date,#consent-other-services-table thead div, #consent-other-services-table thead select').hide();
        $('#other-services-body .edit-other-service').attr('disabled',true);
    });
    $("body").on('click','.cancel-other-service', function (e) {
        var id = $(this).data('id');
        $('#other-services-body #'+id+' .show-td').show();
        $('#other-services-body #'+id+' .edit-td').hide();

        $('#other-services-body #'+id+' .delete-other-service').show();
        $('#other-services-body #'+id+' .edit-other-service').show();
        $('#other-services-body #'+id+' .save-other-service').hide();
        $('#other-services-body #'+id+' .cancel-other-service').hide();

        $('#consent-other-services-table thead #other-service,#consent-other-services-table thead #other-service-main,#consent-other-services-table thead #other-services-quantity,#consent-other-services-table thead .add-other-service,#consent-other-services-table thead #add_other-services_date,#consent-other-services-table thead div, #consent-other-services-table thead select').show();
        $('#other-services-body .edit-other-service').attr('disabled',false);
    });
    $("body").on('click','.save-other-service', function (e) {
        var id = $(this).data('id');
        var id_other_service = $(this).data('idotherservice');
        var id_user = $("body").find('#consent #id_user').val();
        var id_register = $("body").find('#consent #id_register').val();
        var id_visit_purpose = $("body").find('#consent #id_visit_purpose').val();
        var consent_other_service = $('#other-services-body #'+id+' #edit-other-service'+id_other_service+'-hidden').val();
        var consent_other_service_doctor = $('#other-services-body #'+id+' #edit_consent_doctor_other_service').val();
        var consent_other_service_quantity = $('#other-services-body #'+id+' #edit_other_service_quantity').val();
        var consent_other_service_row = $('#other-services-body #'+id+' #edit_other_service_row').val();
        var consent_other_service_date = $('#other-services-body #'+id+' #edit_other_service_date').val();

        $('.save-other-service').attr('disabled',true);

        if(flag==true){
            flag = false;
            $.ajax({
                url: APP_URL+'consentSaveOtherService',
                method: "POST",
                data: {
                    id: id,
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    other_service: consent_other_service,
                    id_doctor: consent_other_service_doctor,
                    quantity: consent_other_service_quantity,
                    row: consent_other_service_row,
                    date: consent_other_service_date,
                    action: 'update',
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data, statusText, xhr) {
                $('.save-other-service').attr('disabled',false);
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }
                
                setTimeout(function(){
                    $('body').find('.row_collapse_'+id_register).click();
                    $('#server-message-other-services').hide('slow').html('').show();
                    $('#server-message-other-services').hide('slow').html('').show();
                },2000);
                if(data.success==false){
                    $('#server-message-other-services').show().html('').html(data.message);
                }else{
                    $('#server-message-other-services').show().html('').html(data.message);
                    $('#other-services-body').html('').html(data.html);
                    $('#consent-other-services-table thead #other-service,#consent-other-services-table thead #other-service-main,#consent-other-services-table thead #other-services-quantity,#consent-other-services-table thead .add-other-service,#consent-other-services-table thead #add_other-services_date,#consent-other-services-table thead div, #consent-other-services-table thead select').show();
                }
            }).fail(function() {
                $('.save-other-service').attr('disabled',false);
            });
            flag = true;
        }
    });

    /*Prescription generate PDF*/
    $('body').on('click','.rx-prescription',function(e) {
        e.preventDefault();
        var url = $(this).data('url');

        if(flag==true){
            flag = false;
            var w = window.open('');
            $.ajax({
                type: "post",
                url: url,
                data: {
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if(data.success==false){
                        alert('PDF file has not been saved!');
                    }

                    if(data.success==true){
                        w.location = data.redirect;
                    }
                    flag = true;
                }
            });
        }
    });
    /*END Prescription generate PDF*/

    /*Consent generate PDF*/
    $('body').on('click','.printFile',function(e) {
        var id_consent = $(this).data('idconsent');
        var id_user = $(this).data('iduser');
        var id_register = $(this).data('idregister');
        var id_visit_purpose = $(this).data('idvisitpurpose');

        if(flag==true){
            flag = false;
            var w = window.open('');
            $.ajax({
                type: "post",
                url: APP_URL+'generateConsentToStorage',
                data: {
                    id_consent: id_consent,
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    /*pdf_action: 'print',*/
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if(data.success==false){
                        alert('PDF file has not been saved!');
                    }

                    if(data.success==true){
                        w.location = data.redirect;
                    }
                    flag = true;
                }
            });
        }
    });
    /*END Consent generate PDF*/
    /*Form Validity Status*/
        $('body').on('click','.saveFormValidityNotes',function(e) {
        var id_form = $(this).data('idform');
        var id_patient = $(this).data('idpatient');
        var id_visit_purpose = $(this).data('idvisitpurpose');
        var id_record = $(this).data('idrecord');
        var status = $(this).data('formvalidity');
        var note = $('#form-validity-note').val();

        if(flag==true){
            $('body').find('.saveFormValidityNotes').attr("disabled", true);
            flag = false;
            $.ajax({
                type: "post",
                url: APP_URL+'saveFormValidityNotes',
                data: {
                    id_form: id_form,
                    id_patient: id_patient,
                    id_visit_purpose: id_visit_purpose,
                    id_record: id_record,
                    status: status,
                    note: note,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('.f_v_meessage').hide();
                    if(data.status=='valid'){
                        $('body').find('#form_validity_value').val(0);
                        $('body').find('.form_tbskin').data('status','invalid').html('Mark as Invalid');
                        $('body').find('#f_v_m_invalid').hide();
                        $('body').find('#f_v_m_valid').show();
                    }

                    if(data.status=='invalid'){
                        $('body').find('#form_validity_value').val(1);
                        $('body').find('.form_tbskin').data('status','valid').html('Mark as Valid');
                        $('body').find('#f_v_m_valid').hide();
                        $('body').find('#f_v_m_invalid').show();
                    }

                    $('body').find('.modalXClose9').click();
                    $('body').find('#server-message').html('').html(data.message);
                    $('body').find('.saveFormValidityNotes').attr("disabled", false);

                    if(data.success==true){
                        $.ajax({
                            type: "post",
                            url: APP_URL+'generatePDFtoOfficeFiles',
                            data: {
                                id_form: id_form,
                                id_patient: id_patient,
                                id_visit_purpose: id_visit_purpose,
                                id_record: id_record,
                                tab: 'office',
                                _token: $('[name="_token"]').val()
                            },
                            success: function(d)
                            {   
                                $('body').find('#server-message').html('').html(d.message);
                                $('body').find('.row_collapse_'+id_patient).click();
                                setTimeout( function (){
                                    $('body').find('.row_collapse_'+id_patient).click();
                                }
                                , 750);
                                //$('body').find('#files-listing-office-'+id_patient).html('').html(d.files);
                                $('body').find('#save_form_to_office_files').attr("disabled", false);
                                flag = true;
                                return;
                            }
                        });
                    }
                    flag = true;
                }
            });
        }
    });

    

    $('body').on('click','.form_tbskin',function(e) {
        var id_form = $(this).data('idform');
        var id_patient = $(this).data('idpatient');
        var id_visit_purpose = $(this).data('idvisitpurpose');
        var id_record = $(this).data('idrecord');
        var status = $(this).data('status');
        var status = $('body').find('.form_tbskin').data('status');

        console.log(status);

        if(flag==true){
            $('body').find('.form_tbskin').attr("disabled", true);
            flag = false;
            $.ajax({
                type: "post",
                url: APP_URL+'getFormValidityData',
                data: {
                    id_form: id_form,
                    id_patient: id_patient,
                    id_visit_purpose: id_visit_purpose,
                    id_record: id_record,
                    status : status,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    setTimeout(function(){
                        $("#formValidityModal").modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                    },1000);


                    $('body').find('.formValidityModalBody').html('').html(data.html);
                    $('body').find('#server-message').html('').html(data.message);
                    $('body').find('.form_tbskin').attr("disabled", false);
                    flag = true;
                }
            });
        }
    });
    /*End Form Validity Status*/
        /*From generate PDF to Patient Files*/
    $('body').on('click','#save_form_to_patient_files',function(e) {
        var id_form = $(this).data('idform');
        var id_patient = $(this).data('idpatient');
        var id_visit_purpose = $(this).data('idvisitpurpose');
        var id_record = $(this).data('idrecord');

        if(flag==true){
            $('body').find('#save_form_to_patient_files').attr("disabled", true);
            flag = false;
            $.ajax({
                type: "post",
                url: APP_URL+'generatePDFtoPatientFiles',
                data: {
                    id_form: id_form,
                    id_patient: id_patient,
                    id_visit_purpose: id_visit_purpose,
                    id_record: id_record,
                    tab: 'patient',
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('#server-message').html('').html(data.message);
                    $('body').find('.row_collapse_'+id_patient).click();
                    setTimeout( function (){
                        $('body').find('.row_collapse_'+id_patient).click();
                    }
                    , 750);
                    $('body').find('#save_form_to_patient_files').attr("disabled", false);
                    flag = true;
                }
            });
        }
    });
    /*END From generate PDF to Patient Files*/
    var prevCompanySelectValue = ''
    $('body').on('focus', '.company_select', function (e) {
        prevCompanySelectValue = this.value;
    })

    $('body').on('focusout', '.company_select', function (e) {
        prevCompanySelectValue = -1;
    })


    $('body').on('change','.company_select', function(e){
        var id_user = $(this).data('iduser');
        var id_company = $(this).val();
        var patient_name = $(this).data('patientname');
        var idregister = $(this).data('idregister');
        var company_name = $(this).find('option:selected').text();

        $('#contact_typein').val('')
        
        if (confirm('Patient ' + patient_name + ' will be added to the ' + company_name+'. Please confirm.')) {
            if (flag == true) {
                flag = false;
                $.ajax({
                    type: "post",
                    url: APP_URL + 'addPatientToCompany',
                    data: {
                        id_company: id_company,
                        id_user: id_user,
                        _token: $('[name="_token"]').val()
                    },
                    success: function (data) {
                        if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                            window.location = data.redirect;
                            return;
                        }

                        if (id_company=='') {
                            $('body').find('.company_files_tab').hide();
                        } else {
                            $('body').find('.company_files_tab').show();
                            setTimeout(function () {
                                $('body').find('.row_collapse_'+idregister).click();
                            }
                            , 750);

                            setTimeout(function () {
                                $('body').find('.row_collapse_' + idregister).click();
                            }
                            , 1750);
                        }

                        $('body').find('.server-message-company_add'+id_user).html('').html(data.message);
                        setTimeout(function () {
                            $('body').find('.server-message-company_add' + id_user).html('').html('');
                        }
                        , 5000);
                        flag = true;
                    }
                });
            }
        } else {
            $(this).val(prevCompanySelectValue);
        }
    })
    /* Patient Company Selection */

    $('body').on('change', '.addFileToCompany', function (e) {
        var add_file = 0
        var addText = 'remove';
        var to = 'from'
        var company_name = $(this).data('companyname');
        var file_name = $(this).data('filename');
        var user_name = $(this).data('username');
        if ($(this).is(":checked")) {
            addText = 'upload';
            to = 'to'
            add_file = 1
        }

        if (confirm('Are you sure you want to ' + addText + ' ' + file_name + ' ' + to + ' ' + company_name+'?')) {
            var id_user = $(this).data('iduser');
            var idfile = $(this).data('idfile');
            var id_company = $(this).data('companyuser');

            if (flag == true) {
                flag = false;
                $.ajax({
                    type: "post",
                    url: APP_URL + 'addPatientFileToCompany',
                    data: {
                        id_company: id_company,
                        idfile: idfile,
                        id_user: id_user,
                        add_file: add_file,
                        _token: $('[name="_token"]').val()
                    },
                    success: function (data) {
                        if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                            window.location = data.redirect;
                            return;
                        }
                        $('body').find('.server-message-company_add' + id_user).html('').html(data.message);

                        if ($(this).is(":checked")) {
                            $(this).prop('checked', false)
                        } else {
                            $(this).prop('checked', true)
                        }
                        flag = true;
                    }
                });
            }
        }else {
            console.log('da');
            if (!$(this).is(":checked")) {
                $(this).prop('checked', true)
            } else {
                $(this).prop('checked', false)
            }
        }
    })
    /* Patient Company Files Selection */

    /* END Patient Compant Selection */

    /*From generate PDF to Office Files*/
    $('body').on('click','#save_form_to_office_files',function(e) {
        var id_form = $(this).data('idform');
        var id_patient = $(this).data('idpatient');
        var id_visit_purpose = $(this).data('idvisitpurpose');
        var id_record = $(this).data('idrecord');

        if(flag==true){
            $('body').find('#save_form_to_office_files').attr("disabled", true);
            flag = false;
            $.ajax({
                type: "post",
                url: APP_URL+'generatePDFtoOfficeFiles',
                data: {
                    id_form: id_form,
                    id_patient: id_patient,
                    id_visit_purpose: id_visit_purpose,
                    id_record: id_record,
                    tab: 'office',
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('#server-message').html('').html(data.message);
                    $('body').find('.row_collapse_'+id_patient).click();
                    setTimeout( function (){
                        $('body').find('.row_collapse_'+id_patient).click();
                    }
                    , 750);
                    $('body').find('#save_form_to_office_files').attr("disabled", false);
                    flag = true;
                }
            });
        }
    });
    /*END From generate PDF to Office Files*/

    $('body').on('click', '.push-webiz-confirm', function(e){
        var id_user = $("body").find('#consent #id_user').val();
        var id_vaccine = $(this).data('id');
        var id_consent = $(this).data('idconsent');

        $.ajax({
            url: APP_URL+'webIZConfirmationModal',
            method: "POST",
            data: {
                id_user: id_user,
                id_vaccine: id_vaccine,
                id_consent: id_consent,
                _token: $('[name="_token"]').val()
            },
            dataType: "json"
        }).done(function (data) {
            if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                window.location = data.redirect;
                return;
            }

            $('body').find('.modalXClose').click();

            setTimeout(function(){
                $("#WebIZConfirmation").modal({
                    backdrop: 'static',
                    keyboard: false
                });
            },1000);

            if(data.success == false){
                $('body').find('.push-webiz').hide();
                $('body').find('.WebIZConfirmationBody').html('').html(data.message);
            }else{
                $('body').find('.push-webiz').show();
                $('body').find('.WebIZConfirmationBody').html('').html(data.html);
            }
        }).fail(function() {

        });
    });

    $('body').on('change', "#webiz_vaccine_completion_status", function() {
        var val = $('#webiz_vaccine_completion_status').val();
        if(val == 'RE'){
            $('#wrap_webiz_substance_refused_reason').show();
        }

        if(val != 'RE'){
            $('#wrap_webiz_substance_refused_reason').hide();
            $('#webiz_substance_refused_reason').get(0).selectedIndex = 0;
        }
    });

    $('body').on('click','.push-webiz', function(e){
        //inchide/deschide pagina de consent
        if(flag==true){
            flag = false;
            $('body').find('.push-webiz').attr("disabled", true);
            $.ajax({
                url: APP_URL+'pushToWebIZ',
                method: "POST",
                data: {
                    form_data: $("#webiz_push_form").serialize(),
                    _token: $('[name="_token"]').val()
                },
                dataType: "json"
            }).done(function (data) {
                /*if(data.success == false){
                    $('body').find('.server-message-webizPush').html('').html(data.message);
                }else{
                    $('body').find('#registerFormDetailsModal').modal('toggle');
                    setTimeout(function(){
                        $('#consentOpen_'+id_consent).click();
                    },1000);
                }*/

                $('body').find('.server-message-webizPush').html('').html(data.message);
                $('body').find('.push-webiz').attr("disabled", false);

                if(data.status_value == 'AA'){
                    $('body').find('#webiz_push_form').addClass('webiz_push_form_disable');
                    $('#getPatientDataFromWebIZ').attr('data-makepdf','1').delay(500).click();
                }
            }).fail(function() {
                $('body').find('.push-webiz').attr("disabled", false);
            });

            flag = true;
        }
    });

    /*Today Push to Webiz*/
    $('body').on('click', '#pushTodayVaccines', function(e){
        $('body').find('.full_overlay').show();
        $.ajax({
            url: APP_URL+'webiz/pushTodayVaccines',
            method: "POST",
            data: {
                _token: $('[name="_token"]').val()
            },
            dataType: "json"
        }).done(function (data) {
            if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                window.location = data.redirect;
                return;
            }

            window.location = data.redirect;
        }).fail(function() {

        });
    });

    /*END Consent Code*/

    /*Staff Details - Location Access*/
    $( "#user_type" ).change(function() {
        var val = $('#user_type').val();
        if(val == 'staff'){
            $('#location-access-wrap').show();
        }

        if(val != 'staff'){
            $('#location-access-wrap').hide();
        }
    });
    /*END Staff Details - Location Access*/

    /*Assume Control*/
    function assumeControl() {
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    password: $('body').find("#assume_password").val(),
                    id_user: $('body').find('#assume_id_user').val(),
                    _token: $('[name="_token"]').val()
                },
                url: $('#assumeControlForm').attr('action'),
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if(data.success==false){
                        $('body').find('#assume-control-message').html(data.html);
                        flag = true;
                        return false;
                    }
                    window.location = data.redirect;
                }
            });
            flag = true;
        }
    }

    function stopAssumecontrol() {
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    password: $('body').find("#assume_password").val(),
                    _token: $('[name="_token"]').val()
                },
                url: $('#stopAssumeControlForm').attr('action'),
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if(data.success==false){
                        $('body').find('#assume-control-message').html(data.html);
                        flag = true;
                        return false;
                    }
                    window.location = data.redirect;
                }
            });
            flag = true;
        }
    }

    $("body").on('click','.stop-assume-control', function (e) {
        stopAssumecontrol();
    });

    $("body").on('click','.assume-control', function (e) {
        assumeControl();
    });

    $('body').on('submit','#stopAssumeControlForm', function (e) {
        e.preventDefault();
        stopAssumecontrol();
    });

    $('body').on('submit','#assumeControlForm', function (e) {
        e.preventDefault();
        assumeControl();
    });

    /*End Assume Control*/

    /*Focus Modals*/
    $('body').on('click',"button[data-target='#assumeControl']",function(){
        setTimeout(function(){ $('body').find('#assume_password').focus(); }, 1000);
    });

    $('body').on('click',"button[data-target='#stopAssumeControl']",function(){
        setTimeout(function(){ $('body').find('#assume_password').focus(); }, 1000);
    });
    /*End Focus Modals*/

    /*Start Pt. Notes*/
    $('body').on('change', '#notes-list', function() {
        var value = $(this).val();
        if(value=='add'){
            $("#addNoteModal").modal({
                backdrop: 'static',
                keyboard: false
            }); 

            $('#addNoteModal').on('shown.bs.modal', function() {
                $('#add_note').focus();
            })
        }
    });

    $('body').on('click',"button[data-close=1]",function(){
        $('body').find('#addNoteModal').modal('toggle');
    });

    $('body').on('click',"button[data-close=2]",function(){
        $('body').find('#WebIZConfirmation').modal('toggle');
    });

    $('body').on('click',"button[data-close=3]",function(){
        $('body').find('#visitPurposeNotesModal').modal('toggle');
    });

    $('body').on('click',"button[data-close=4]",function(){
        $('body').find('#tempPasswordModal').modal('toggle');
    });

    $('body').on('click',"button[data-close=5]",function(){
        $('body').find('#visitEditPregnancyModal').modal('toggle');
    });

    $('body').on('click',"button[data-close=6]",function(){
        $('body').find('#uploadFileModal').modal('toggle');
    });

    $('body').on('click',"button[data-close=7]",function(){
        $('body').find('#dobModal').modal('toggle');
    });

    $('body').on('click',"button[data-close=8]",function(){
        if (confirm('Are you sure you want to cancel this submission?')) {
            $('body').find('#patientLabSpreadsheetModal').modal('toggle');
        }
    });

    $('body').on('click',"button[data-close=9]",function(){
        $('body').find('#formValidityModal').modal('toggle');
    });

    $('body').on('click',"button[data-close=10]",function(){
        $('body').find('#consentVaccineAuthorisationModal').modal('toggle');
    });

    $('body').on('click', "button[data-close=11]", function () {
        $('body').find('#servicesDeleteModal').modal('toggle');
    });

    $('body').on('click', "button[data-close=12]", function () {
        $('body').find('#reProcessPaymentModal').modal('toggle');
    });

    $('body').on('click', "button[data-close=13]", function () {
        $('body').find('#testsCheckModal').modal('toggle');
    });

    $('body').on('click', ".modalXClose,#closeFormModal", function () {
        if (tbSkinClose == false) {
            if (confirm('YOU HAVE NOT COMPLETED THE REQUIRED FIELDS, ARE YOU SURE YOU WANT TO CLOSE BEFORE COMPLETING?')) {
                $('body').find('#dynamic-modal').modal('toggle');
                tbSkinClose = true;
            } 
            return;
        } else {
            $('body').find('#dynamic-modal').modal('toggle');
        }
    });



    $('body').on('click', ".servicesModalClose,.modalXCloseService", function () {
        /* Close Consent */
        if ($(".printFile").prop('disabled')) {
            if (confirm('The Consent has unsaved data. Are you sure you want to continue?')) {
                $('body').find('.printFile').attr("disabled", false);
                $('body').find('#servicesModal').modal('toggle');
            }
        }else {
            $('body').find('#servicesModal').modal('toggle');
        }
    /* ****************** */
    });



    $('body').on('click',"button[data-close=tbskinPin]",function(){
        $('body').find('#tbskin_pin').val('');
        localStorage.removeItem('tbskinButtonId');
        $('body').find('#tbskinPin').modal('toggle');
    });

    $('body').on('click', '.saveNote', function() {
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    add_note: $('body').find("#add_note").val(),
                    id_visit_purpose: $('body').find("#id_visit_purpose").val(),
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'note/saveAjax',
                success: function(data)
                {
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('.addNoteModalClose').click();

                    $('body').find('#add-note-tovp-message').html(data.message);
                    
                    if (typeof data.html != 'undefined'){
                        $('body').find('#notes-listing-body').html('').html(data.html);
                    }


                    var option = '<option>Notes:</option><option value="add">Add New Note</option>';

                    if (typeof data.notes != 'undefined'){
                        var notes = data.notes;
                        notes.forEach(function(note){
                            option=option+'<option value="'+note.id+'">'+note.note+'</option>';
                        });
                        $('body').find('#notes-list').html('').html(option);
                    }

                    $('body').find('#add-note-message').html(data.html);
                    
                    $('body').find("#add_note").val('');
                    $('#notes-list option:eq(0)').attr('selected','selected');

                    setTimeout(function(){
                        $('body').find('#add-note-message').html('').hide();
                    },5000);
                }
            });
            flag = true;
        }
    });


    $('body').on('change', '#observed_by_supervising_filter', function() {
        $('#observed_by_supervising_from').submit();
    });

    $('body').on('change', '.observed_by_supervising', function() {
        if(this.checked) {
            if (this.id == 'observed_by_baktari') {
                $('#observed_by_thomas').prop("checked", false);
            } else {
                $('#observed_by_baktari').prop("checked", false);
            }
        } 
        
        $.ajax({
            type: "POST",
            data: {
                id_visit_purpose: $('body').find("#id_visit_purpose_vpnm").val(),
                observed_by_baktari: $('body').find("#observed_by_baktari").prop("checked") ? 1 : 0,
                observed_by_thomas: $('body').find("#observed_by_thomas").prop("checked") ? 1 : 0,
                _token: $('[name="_token"]').val()
            },
            url: APP_URL+'note/addObservedBySupervising',
            success: function(data)
            {   
            
            }
        });
        flag = true;
    });


    $('body').on('click', '.add-note', function() {
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    id_visit_purpose: $('body').find("#id_visit_purpose_vpnm").val(),
                    id_patient: $('body').find("#id_patient_vpnm").val(),
                    vpnm_data: $('body').find('#vpnm_data').val(),
                    vpnm_note: $('body').find('#vpnm_note').val(),
                    vpmn_name_writer: $('body').find('#vpmn_name_writer').val(),
                   
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'note/addNoteToVP',
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('#add-note-tovp-message').html(data.message);

                    if (typeof data.html != 'undefined'){
                        $('body').find('#notes-listing-body').html('').html(data.html);
                        //$('#vpnm_data').val('');
                        $('#vpnm_note').val('');
                        $("#canned_note").prop("selectedIndex", 0);
                        //$('#vpmn_name_writer').val('');
                    }

                    setTimeout(function(){
                        $('body').find('#add-note-tovp-message').html('');
                    },5000);
                }
            });
            flag = true;
        }
        $("#notes-list").val($("#notes-list option:first").val());
    });

    $('body').on('click', '.update-note', function() {
        if (confirm('Are you sure you want to update this patient note?')) {
            if(flag==true){
                flag = false;
                var name_of_writer = $('body').find('#_name_of_writer').val()
                var d = $('body').find('#_note_date').val()
                $.ajax({
                    type: "POST",
                    data: {
                        id_visit_purpose: $('body').find("#id_visit_purpose_vpnm").val(),
                        id_patient: $('body').find("#id_patient_vpnm").val(),
                        vpnm_data: $('body').find('#vpnm_data').val(),
                        vpnm_note: $('body').find('#vpnm_note').val(),
                        vpmn_name_writer: $('body').find('#vpmn_name_writer').val(),
                        vpmn_edit_id: $('body').find('#vpmn_edit_id').val(),
                        vpmn_edit_id_vp_entry: $('body').find('#vpmn_edit_id_vp_entry').val(),
                        _token: $('[name="_token"]').val()
                    },
                    url: APP_URL+'note/updateNoteToVP',
                    success: function(data)
                    {   
                        if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                            window.location = data.redirect;
                            return;
                        }

                        $('body').find('#add-note-tovp-message').html(data.message);

                        if (typeof data.html != 'undefined'){
                            $('body').find('#notes-listing-body').html('').html(data.html);
                            $('#vpnm_data').val(d);
                            $('#vpnm_note').val('');
                            $("#canned_note").prop("selectedIndex", 0);
                            $('#vpmn_name_writer').val(name_of_writer);
                        }

                        if(data.success==true){
                            $('body').find('.update-note').hide();
                            $('body').find('.add-note').show();
                            $('body').find('.cancel-note').hide();
                        }

                        setTimeout(function(){
                            $('body').find('#add-note-tovp-message').html('');
                        },5000);
                    }
                });
                flag = true;
            }
            $("#notes-list").val($("#notes-list option:first").val());
        }
    });

    $('body').on('click', '.delete-note', function() {
        if (confirm('Are you sure you want to delete this?')) {
            if(flag==true){
                flag = false;
                $.ajax({
                    type: "POST",
                    data: {
                        id_visit_purpose: $('body').find("#id_visit_purpose_vpnm").val(),
                        id_note: $(this).data('id'),
                        id_patient: $('body').find("#id_patient_vpnm").val(),
                        _token: $('[name="_token"]').val()
                    },
                    url: APP_URL+'note/deleteNoteFromVP',
                    success: function(data)
                    {   
                        if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                            window.location = data.redirect;
                            return;
                        }

                        $('body').find('#add-note-tovp-message').html(data.message);
                        $('body').find('#notes-listing-body').html('').html(data.html);

                        setTimeout(function(){
                            $('body').find('#add-note-tovp-message').html('');
                        },5000);
                    }
                });
                flag = true;
            }
        }
    });

    $('body').on('click', '.edit-note', function() {
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    id_visit_purpose: $('body').find("#id_visit_purpose_vpnm").val(),
                    id_note: $(this).data('id'),
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'note/editNoteFromVP',
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('#vpnm_data').val(data.data.date);
                    $('body').find('#vpnm_note').val(data.data.note);
                    $('body').find('#vpmn_name_writer').val(data.data.name_writer);
                    $('body').find("#canned_note").prop("selectedIndex", 0);

                    $('body').find('#vpmn_edit_id').val(data.data.id);
                    $('body').find('#vpmn_edit_id_vp_entry').val(data.data.id_vp_entry);

                    $('body').find('.update-note').show();
                    $('body').find('.add-note').hide();
                    $('body').find('.cancel-note').show();
                }
            });
            flag = true;
        }
    });

    $('body').on('click', '.cancel-note', function() {
        var name_of_writer = $('body').find('#_name_of_writer').val()
        var d = $('body').find('#_note_date').val()

        $('body').find('#vpnm_data').val(d);
        $('body').find('#vpnm_note').val('');
        $('body').find('#vpmn_name_writer').val(name_of_writer);

        $('body').find('#vpmn_edit_id').val('');
        $('body').find('#vpmn_edit_id_vp_entry').val('');
        $('body').find("#canned_note").prop("selectedIndex", 0);

        $('body').find('.update-note').hide();
        $('body').find('.add-note').show();
        $('body').find('.cancel-note').hide();
    });
    /*END Pt. Notes*/
    $('body').on('click', '.update-red-alert', function() {
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    red_alert_text: $('body').find("#red-alert-text").val(),
                    id_user: $('#id_user').val(),
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'updateRedAlert',
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('#red-alert-message').html(data.html);

                    setTimeout(function(){
                        $('body').find('#red-alert-message').html('');
                    },5000);

                    var a = $('.rowid_'+data.rowid+' a');
                    if(data.red_alert==true){
                        a.attr('data-original-title','- Red Alert available!');
                        $('.rowid_'+data.rowid+' .table-row-bullet').addClass('redErrorBackground');
                        $('.rowid_'+data.rowid+' .table-row-triangle').addClass('redError');

                        $('.rowid_'+data.rowid+' .table-row-triangle').removeClass('glyphiconArrowCounter');
                    }

                    if(data.red_alert==false){
                        a.attr('data-original-title','');
                        $('.rowid_'+data.rowid+' .table-row-bullet').removeClass('redErrorBackground');
                        $('.rowid_'+data.rowid+' .table-row-triangle').removeClass('redError');

                        $('.rowid_'+data.rowid+' .table-row-triangle').addClass('glyphiconArrowCounter');
                    }
                }
            });
            flag = true;
        }
    });
    /*Red Alert*/

    /*End Red Alert*/
    /*Remove Pregnancy*/
    $('body').on('click','.edit_pregnancy_vp', function(event) {
        var visitpurpose = $(this).data('visitpurpose');
        
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'editPregnancyVP',
                data: { 
                    visitpurpose: visitpurpose,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('#visitEditPregnancyModal div.modal-body').html(data.html);
                }
            });
            flag = true;
        }
    });

     $('body').on('click','#pregnancy_modal_submit', function() {
        var visitpurpose = $('body').find('#visitpurpose_preg').val();
        var pregnancy_notice = $('input[name="pregnancy_notice"]:checked').val();
        
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'submitPregnancyVP',
                data: { 
                    visitpurpose: visitpurpose,
                    pregnancy_notice: pregnancy_notice,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('#edit_pregnancy_vp'+visitpurpose).attr("src",data.src);
                    $('body').find('#visitEditPregnancyModal').modal('toggle');
                }
            });
            flag = true;
        }
    });
    /*End Remove Pregnancy*/


    $("body").tooltip({   
        selector: "[data-toggle='tooltip']",
        container: "body",
        html: true
    })

    /*Safari Other Services Search {combobox}*/
    /*var otherServiceSearch = $('body').find('#other-service');
    otherServiceSearch.combobox();
    otherServiceSearch.parent().on("focus", ".custom-combobox input", function (event, ui) {
       $(this).val('');
    });

    otherServiceSearch.parent().on("keypress", ".custom-combobox input", function (event) {
        alert('start');
    });*/

    /* END Safari Other Services Search {combobox}*/

    /*Start Temp Password*/
    /*END Pt. Notes*/
    $('body').on('click','.temp-password', function(e) {
        var id_user = $(this).data('temppass');
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    id_user: id_user,
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'tempPassword',
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('.tempPasswordModalBody').html('').html(data.message);
                    $("#tempPasswordModal").modal({
                        backdrop: 'static',
                        keyboard: false
                    });
                }
            });
            flag = true;
        }
    });
    /*End Temp Password*/
    $('body').on('click','#signConsentPDF', function(e) {
        e.preventDefault();
        var id_user = $('body').find('#id_user').val();
        var id_register = $('body').find('#id_register').val();
        var id_visit_purpose = $('body').find('#id_visit_purpose').val();

        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'signConsentPDF',
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('#sign-tab').html('').html(data.html);
                }
            });
            flag = true;
        }

    });

    /*Save To File*/
    $('body').on('click','.saveToFile', function(e) {
        var id_user = $('body').find('#id_user').val();
        var id_register = $('body').find('#id_register').val();
        var id_visit_purpose = $('body').find('#id_visit_purpose').val();

        $('.saveToFile').attr('disabled',true);

        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                data: {
                    id_user: id_user,
                    id_register: id_register,
                    id_visit_purpose: id_visit_purpose,
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'saveToFile',
                success: function(data)
                {   
                    $('.saveToFile').attr('disabled',false);
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if(data.success==false){
                        alert('PDF file has not been saved!');
                    }
                    
                    $('body').find('.row_collapse_'+id_register).click();
                    $('body').find('#server-message').html('').html(data.message);
                    setTimeout( function (){
                        $('body').find('.row_collapse_'+id_register).click();
                    }
                    , 750);
                    /*if(data.success==true){
                        $('body').find('.modalXClose').click();
                    }*/
                    
                }
            });
            flag = true;
        }

    });
    /*END Save To File*/

    /*Pensil Sign Stuff*/
    $('body').on('click','.main_pencil',function(e) {
        var section = $(this).data('section')
        var id_user = $(this).data('iduser');
        var id_typein = $(this).data('idtypein');
        var signstate = $(this).data('signstate');
        var self = $(this);
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'getSignatureData',
                data: {
                    id_user: id_user,
                    section: section,
                    id_typein: id_typein,
                    signstate: signstate,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if(signstate==1){
                        $('body').find('#signature_actions_'+section).html('').html(data.html).show();
                    }else{
                        $('body').find('#signature_actions_'+section).html('').html(data.html);
                        setTimeout( function (){
                            $('.sign_menu_'+section).find('.user_signature_select').click();
                            $('.sign_menu_'+section).find('.user_initial_select').click();
                        },100);
                    }


                    flag = true;
                }
            });
        }
        e.preventDefault();
    });

    $('body').on('click','.user_signature_select',function(e) {
        var section = $(this).data('section')
        var id_user = $(this).data('iduser');
        var id_typein = $(this).data('idtypein');
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'getSelectedSignature',
                data: {
                    id_user: id_user,
                    section: section,
                    col: 'user_signature',
                    id_typein: id_typein,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('#typein'+section).click;
                    $('body').find('#patient_st_status'+section).val('signature');
                    $('body').find('#'+section).val(data.data);
                    $('body').find('.'+section).val(data.data);
                    $('body').find('#'+id_typein).val('');
                    res = section.split("_");
                    $('body').find('.'+res[0]+'_typein').val('');


                    $('body').find('.display_initial_'+section).hide();
                    $('body').find('#display_'+section).show().attr('src',data.data);
                    $('body').find('#signature_actions_'+section).html('').hide();

                    $('body').find('.typein_wrapper'+section).addClass('hide');
                    $('body').find('.signature_wrapper'+section).removeClass('hide');
                    flag = true;
                }
            });
        }
        e.preventDefault();
    });

    $('body').on('click','.user_initial_select',function(e) {
        var section = $(this).data('section')
        var id_user = $(this).data('iduser');
        var id_typein = $(this).data('idtypein');
        var signstate = $(this).data('signstate');
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'getSelectedSignature',
                data: {
                    id_user: id_user,
                    section: section,
                    col: 'user_initial',
                    id_typein: id_typein,
                    signstate: signstate,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('#typein'+section).click;
                    $('body').find('#patient_st_status'+section).val('typein');
                    $('body').find('.'+section+'_typein').val(data.data);
                    $('body').find('.'+id_typein).val(data.data);
                    $('body').find('#'+section).val('');
                    $('body').find('.'+section).val(data.data);
                    res = section.split("_");
                    $('body').find('.'+res[0]+'_typein').val(data.data);
                    $('body').find('.'+section+'_typein').val(data.data);

                    $('body').find('#display_'+section).hide();
                    $('body').find('.display_initial_'+section).show().html(data.data);
                    $('body').find('#signature_actions_'+section).html('').hide();

                    $('body').find('.typein_wrapper'+section).removeClass('hide');
                    // /$('body').find('.signature_wrapper'+section).addClass('hide');
                    flag = true;
                }
            });
        }
        e.preventDefault();
    });

    $('body').on('click','.delete_signature_initial',function(e) {
        var section = $(this).data('section')
        var col = $(this).data('col');
        var id_user = $(this).data('iduser');
        var id_typein = $(this).data('idtypein');
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'deleteSignatureInitial',
                data: {
                    id_user: id_user,
                    section: section,
                    col: col,
                    id_typein: id_typein,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }
                    if(col=='user_initial'){
                        $('body').find('#'+section).val(data.data);
                        $('body').find('.'+section).val(data.data);
                        $('body').find('#'+id_typein).val(data.data);
                        $('body').find('.display_initial_'+section).html('').hide();
                    }

                    if(col=='user_signature'){
                        $('body').find('.'+section+'_typein').val(data.data);
                        $('body').find('#display_'+section).html('');
                    }

                    $('body').find('#display_'+section).hide().html('');
                    $('body').find('#signature_actions_'+section).html('').hide();
                    flag = true;
                }
            });
        }
        e.preventDefault();
    });

    $('body').on('click','.add_signature_pencil,.add_initial_pencil',function(e) {
        var section = $(this).data('section')
        var col = $(this).data('col');
        var id_user = $(this).data('iduser');
        var id_typein = $(this).data('idtypein');
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'getSignatureModal',
                data: {
                    id_user: id_user,
                    section: section,
                    col: col,
                    id_typein: id_typein,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('.pencilSignModalBody').html('').html(data.html);
                    $("#pencilSignModal").modal({
                        backdrop: 'static',
                        keyboard: false
                    });

                    if(col=='user_initial'){
                        setTimeout( function (){
                            $('body').find('#typeinpencil').click();
                        },150);
                    }
                }
            });
            flag = true;
        }
        e.preventDefault();
    });

    $('body').on('click','#save_signature_pencil', function(e) {
        if(flag==true){
            $('body').find('#save_signature_pencil').attr("disabled", true);
            var section = $(this).data('section');
            flag = false;
            $.ajax({
                type: "POST",
                data: { 
                    form_data: $("#save_signature_pencil_form").serialize(),
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'saveSignaturePencil',
                success: function(data)
                {   

                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('.signSubmit').attr("disabled", false);
                    $('body').find('#server-message-sign').html('').html(data.message);
                    $('body').find('#pencilSignModal').modal('toggle');

                    if(data.data.status == 'signature'){
                        $('body').find('#patient_st_status'+section).val('signature');
                        $('body').find('#'+section).val(data.data);
                        $('body').find('.'+section).val(data.data);
                        //$('body').find('#'+id_typein).val('');
                        res = section.split("_");
                        $('body').find('.'+res[0]+'_typein').val('');
                        $('body').find('.'+section+'_typein').val(data.data);


                        $('body').find('.display_initial_'+section).hide();
                        $('body').find('#display_'+section).show().attr('src',data.data.data);
                        $('body').find('#signature_actions_'+section).html('').hide();

                        $('body').find('.typein_wrapper'+section).addClass('hide');
                        $('body').find('.signature_wrapper'+section).removeClass('hide');
                    }

                    if(data.data.status == 'typein'){
                        $('body').find('#patient_st_status'+section).val('typein');
                        $('body').find('.'+section+'_typein').val(data.data);
                        //$('body').find('.'+id_typein).val(data.data);
                        $('body').find('#'+section).val('');
                        $('body').find('.'+section).val(data.data);
                        res = section.split("_");
                        $('body').find('.'+res[0]+'_typein').val(data.data);

                        $('body').find('#display_'+section).hide();
                        $('body').find('.display_initial_'+section).show().html(data.data.data);
                        $('body').find('#signature_actions_'+section).html('').hide();

                        $('body').find('.typein_wrapper'+section).removeClass('hide');
                    }
                    flag = true;
                    return;
                }
            });
        }
    });

    $('body').on('click','.signature_pin_code',function(e) {
        var section = $(this).data('section')
        var id_user = $(this).data('iduser');
        var pin_code = $('body').find('.pin_code_'+section).val();
        var id_typein = $(this).data('idtypein');
        var is_staff_initials = $('body').find('.is_staff_initials_' + section).val();
        var pinURL = $('body').find('.pinURL').val();
        var id_visit_purpose = $('body').find('input[name=id_visit_purpose]').val();
        var id_form = $('body').find('input[name=id_form]').val();
        var id_record = $('body').find('input[name=id_record]').val();
        var pin_code_type = $(this).data('pincodetype')

        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+pinURL,
                data: {
                    id_user: id_user,
                    section: section,
                    pin_code: pin_code,
                    id_typein: id_typein,
                    is_staff_initials: is_staff_initials,
                    id_visit_purpose: id_visit_purpose,
                    id_form: id_form,
                    id_record: id_record,
                    pin_code_type: pin_code_type,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if(data.message != false){
                        $('body').find('.user_initial_select').show();
                        $('body').find('#pin_code_'+section).hide();

                        $('body').find('#'+section).val(data.html);
                        $('body').find('.'+section).val(data.html);
                        $('body').find('.'+section+'_typein').val(data.html);

                        $('body').find('.display_initial_'+section).html(data.html).show();
                        $('body').find('#pin_code_info_' + section).hide();

                    }else{
                        alert('Pin Code si invalid!');
                    }
                    flag = true;
                }
            });
        }
        e.preventDefault();
    });

    $('body').on('click','.update_signature_pin_code',function(e) {
        var section = $(this).data('section')
        var id_user = $(this).data('iduser');
        var pin_code = $('body').find('.pin_code_'+section).val();
        var id_typein = $(this).data('idtypein');
        var updatePinURL = $('body').find('.updatePinURL').val();

        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+updatePinURL,
                data: {
                    id_user: id_user,
                    section: section,
                    pin_code: pin_code,
                    id_typein: id_typein,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }
                    flag = true;
                    if(data.success==true){
                        $('#signature_pin_code_'+section).click();
                        $('body').find('.has_pin_code').html('PIN Code set').css('color','green');
                    }

                    $('body').find('#message_pin_code_'+section).html('').html(data.message);
                    setTimeout( function (){
                        $('#message_pin_code_'+section).html('');
                    },5000);
                    $('body').find('#pin_code_info_' + section).hide();
                    //$('body').find('.pin_code_'+section).val('');
                    
                }
            });
        }
        e.preventDefault();
    });
    /*END Pensil Sign Stuff*/

    /*Save Sign Form*/

    $('body').on('click','.signSubmit', function(e) {
        if(flag==true){
            $('body').find('.signSubmit').attr("disabled", true);
            flag = false;
            $.ajax({
                type: "POST",
                data: { 
                    form_data: $("#saveConsentPDF").serialize(),
                    _token: $('[name="_token"]').val()
                },
                url: APP_URL+'signSave',
                success: function(data)
                {   
                    $('body').find('#server-message-sign').html('').show();
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('body').find('.consentVaccineAuthorisationModalBody').html('');
                    if(typeof(data.modalApprove) != "undefined" && (typeof id_clinician == "undefined" || id_clinician == '')){
                        setTimeout(function(){
                            $("#consentVaccineAuthorisationModal").modal({
                                backdrop: 'static',
                                keyboard: false
                            });
                        },1000);

                        $('body').find('.saveToFile').prop('disabled',false);
                        $('body').find('.printFile').prop('disabled',false);
                        $('body').find('.signSubmit').attr("disabled", false);
                        $('body').find('.consentVaccineAuthorisationModalBody').html('').html(data.modalApprove);
                        return;
                    }

                    $('body').find('.signSubmit').attr("disabled", false);
                    $('body').find('#server-message-sign').html('').html(data.message);

                    if(data.success==true){
                        $('body').find('.saveToFile').prop('disabled',false);
                        $('body').find('.printFile').prop('disabled',false);
                        $('body').find('.saveToFile').click();
                    }

                    if (typeof (data.alert) != "undefined") {
                        alert(data.alert);
                    }
                }
            });
            flag = true;
        }
    });
    /*END Save Sign Form*/

    ///////////////////////////////
    /* $('body').on('keyup', '.search-pls-test', function (e) {
        $('body').find('.test').show();

        var search = $(this).val();
        var len = search.length
        if (len < 3) {
            return;
        }

        var id = $(this).attr('list');


        var url = $('body').find('#pls-test-search-url').val();
        if (flag == true) {
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL + url,
                data: {
                    search: $(this).val(),
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                        window.location = data.redirect;
                        return;
                    }

                    var dataList = data.tests;
                    // Clear the list
                    var list = $('#ts-' + id);
                    list.html('');

                    var li_elements = "";
                    for (var i = 0; i < dataList.length; i++) {
                        li_elements += '<li data-testname="' + dataList[i].pls_short_name +'"  class="list-group-item test-select">' + dataList[i].pls_short_name + '</li>';
                    }
                    $('body').find('#ts-' + id).html(li_elements);
                }
            });
            //listid = '';
            flag = true;
        }

        $(window).click(function () {
            $('body').find('.test').hide();
        });
    });

    $('body').on('click', '.test-select', function (event) {
        event.stopPropagation();
        var test = $(this).data('testname');
        $('.search-pls-test').val(test);
        $('.filter_submit').click()
    }) */
    //////////////////////////////



    /*Company Search Dropdown*/
    $('body').on('keyup', '.search-company', function (e) {
        $('body').find('.test').show();

        var search = $(this).val();
        var len = search.length
        if(len<3) {
            return;
        }

        var id = $(this).attr('list');
        var iduser = $(this).data('iduser');
        var listid = $(this).data('listid');
        var idregister = $(this).data('idregister');

        var url = $('body').find('#company-search-url').val();
        if (flag == true) {
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL + url,
                data: {
                    search: $(this).val(),
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                        window.location = data.redirect;
                        return;
                    }

                    var dataList = data.companies;
                    // Clear the list
                    var list = $('#cs-' + id);
                    list.html('');

                    var li_elements = "";
                    for (var i = 0; i < dataList.length; i++) {
                        li_elements += '<li data-idregister=' + idregister +' data-cs_row=' + listid + ' data-company_id=' + dataList[i].id + ' data-iduser=' + iduser +' class="list-group-item test-lic">' + dataList[i].company_name + '</li>';
                    }
                    $('body').find('#cs-' + listid).html(li_elements); 
                }
            });
            //listid = '';
            flag = true;
        }

        $(window).click(function () {
            $('body').find('.test').hide();
        });
    });

    $('body').on('click', '.test-lic', function (event) {
        event.stopPropagation();
        var id = $(this).data('list_id');
        var company_id = $(this).data('company_id');
        var iduser = $(this).data('iduser');
        var idregister = $(this).data('idregister');

        var tr_id = $(this).closest('tr').prop('id');

        $('body').find('.test').hide();

        var patient_name = $('#patient_name_' + iduser).val();
        var old_value = $('body').find('#companyName_' + iduser).val();

        if (confirm('Patient ' + patient_name + ' will be added to the ' + $(this).text() + '. Please confirm.')) {
            if (flag == true) {
                $('body').find('#companies-search' + iduser).val($(this).text());
                $('body').find('#company-main-hidden_' + iduser + '-hidden').val(company_id);
                flag = false;
                $.ajax({
                    type: "post",
                    url: APP_URL + 'addPatientToCompany',
                    data: {
                        id_company: company_id,
                        id_user: iduser,
                        _token: $('[name="_token"]').val()
                    },
                    success: function (data) {
                        if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                            window.location = data.redirect;
                            return;
                        }

                        setTimeout(function () {
                            $('body').find('.row_collapse_' + idregister).click();
                        }
                            , 750);

                        console.log(idregister)
                        setTimeout(function () {
                            $('body').find('.row_collapse_' + idregister).click();
                        }
                            , 1750);

                        $('body').find('#remove_patient_from_company_' + iduser).show();
                    }
                });
            }
            flag = true
        }else {
            flag = true
            $('body').find('#companies-search' + iduser).val(old_value);
        }
    });


    $('body').on('click', '.remove_patient_from_company', function (event) {
        if (confirm('Are you sure you want to remove patient from company?')) {
            if (flag == true) {
                var iduser = $(this).data('iduser');
                var idregister = $(this).data('idregister');
                flag = false;
                $.ajax({
                    type: "post",
                    url: APP_URL + 'addPatientToCompany',
                    data: {
                        id_user: iduser,
                        _token: $('[name="_token"]').val()
                    },
                    success: function (data) {
                        if (typeof (data.token_mismatch) != "undefined" && data.token_mismatch) {
                            window.location = data.redirect;
                            return;
                        }

                        setTimeout(function () {
                            $('body').find('.row_collapse_' + idregister).click();
                        }
                            , 750);


                        setTimeout(function () {
                            $('body').find('.row_collapse_' + idregister).click();
                        }
                            , 1750);

                        $('body').find('#remove_patient_from_company_' + iduser).hide();
                        flag=true
                    }
                });
            }
        }
    })
    /*Company Search Dropdown*/

    /*Other Service Search Dropdown*/
    $('body').on('keyup','.search-os', function(e) {
        $('body').find('.test').show();

        var id = $(this).attr('list');
        var listid = $(this).data('listid');

        var url = $('body').find('#other-service-url').val();
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+url,
                data: { 
                    search: $(this).val(),
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    var dataList = data.data;
                    // Clear the list
                    var list = $('#os-'+id);
                    list.html('');

                    var li_elements = "";
                    for(var i=0;i<dataList.length;i++){
                        li_elements += '<li data-os_row='+listid+' data-list_id='+dataList[i].id+' data-idlab='+dataList[i].id_laborator+' class="list-group-item test-li">['+dataList[i].lab_name+'] '+dataList[i].other_service.trim()+'</li>';
                    }
                    $('body').find('#os-'+listid).html(li_elements);
                }
            });
            //listid = '';
            flag = true;
        }

        $(window).click(function() {
            $('body').find('.test').hide();
        });


        $('body').on('click','.test-li', function(event) {
            event.stopPropagation();
            var id = $(this).data('list_id');
            var id_row = $(this).data('os_row');
            var id_laborator = $(this).data('idlab');

            var tr_id = $(this).closest('tr').prop('id');
            
            $('body').find('.test').hide();

            if(id_row!='main'){
                $('body').find('#edit-other-service'+id_row).val($(this).text());
                $('body').find('#edit-other-service'+id_row+'-hidden').val(id);

                if(id_laborator==5)
                    $('body').find('.select-'+tr_id).removeClass('hide').show();
                else
                    $('body').find('.select-'+tr_id).hide().val('');
            }else{
                //$('body').find('#other-service-hidden').val(id);
                $('body').find('#other-service-'+id_row).val($(this).text());
                $('body').find('#other-service-'+id_row+'-hidden').val(id);

                if(id_laborator==5)
                    $('body').find('#consent_doctor_other-services').show();
                else
                    $('body').find('#consent_doctor_other-services').hide();
            }
        });
    });
    /*Other Service Search Dropdown*/

    /*IP Address Switch*/
    $( "#ip_access_switch" ).change(function() {
        var Switch = $(this).is(':checked');
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'ipAccessSwitch',
                data: { 
                    Switch: Switch ? 'on' : 'off',
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('#server-message').html('').html(data.html);
                }
            });
            //listid = '';
            flag = true;
        }
    });
    /*END IP Address Switch*/

    /*APP Config Switch*/
    $( ".app_config_switch" ).change(function() {
        var property = $(this).data('property');
        var value = $(this).is(':checked');
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'appConfigSwitch',
                data: { 
                    value: value ? 'on' : 'off',
                    property: property,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('#server-message').html('').html(data.html);
                }
            });
            //listid = '';
            flag = true;
        }
    });
    /*END APP Config Switch*/

    $('.submit-form-patient').click(function(){
        //$('html,body').animate({ scrollTop: 0 }, 'slow');
    });

    /*Patient Lab Spreadhseet*/
    $(".main-table").clone(true).appendTo('#table-scroll').addClass('clone');

    $('body').on('click','.patient-lab-spreadsheet-modal', function(event) {
        $('.patient-lab-spreadsheet-modal').attr('disabled',true);
        if(flag==true){
            flag = false;
            var id_other_service = $(this).data('idotherservice');
            var id_consent = $(this).data('idconsent');
            var id_user = $('#id_user').val();
            $.ajax({
                type: "POST",
                url: APP_URL+'patientLabSpreadsheetModal',
                data: { 
                    id_user: id_user,
                    id_other_service: id_other_service,
                    id_consent: id_consent,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    $('.patient-lab-spreadsheet-modal').attr('disabled',false);
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }
                    //$('#server-message-other-services').html('').html(data.message);
                    $('body').find('.patientLabSpreadsheetModalBody').html('').html(data.html);
                }
            });
            flag = true;
        }
        $('body').find('#patientLabSpreadsheetModal').modal('toggle');
    });

    /*$('body').on('click','.to-be-disabled input,.to-be-disabled label', function(event) {
        if($(this).is(':disabled')){
            $(this).removeClass('disabled');
        }
        $('.to-be-disabled input,.to-be-disabled label')
            .removeClass('disabled')
            .not(this)
            .addClass('disabled');
    });*/

    $('body').on('click','#submit-lab-spreadsheet', function(event) {
        $('#submit-lab-spreadsheet').attr('disabled',true);
        if(flag==true){
            flag = false;
            var id_user = $('#id_user').val();
            var id_patient = $('#id_register').val();
            var id_visit_purpose = $('#id_visit_purpose').val();
            var id_other_service = $(this).data('idotherservice');
            var id_consent = $(this).data('idconsent');
            var contact_typein = $('#contact_typein').val();
            var contact_check = $('input[name="contact_check"]:checked').val();
            var spreadsheet_location = $('#spreadsheet_location').val();
            var employee_initials = $('#employee_initials').val();
            var spreadsheet_notes = $('#spreadsheet_notes').val();
            var pls_favorites = [];

            $("input:checkbox[name=pls_favorites]:checked").each(function () {
                pls_favorites.push($(this).val());
            });

            $.ajax({
                type: "POST",
                url: APP_URL+'savePatientLabSpreadsheet',
                data: { 
                    id_user: id_user,
                    id_patient: id_patient,
                    id_visit_purpose: id_visit_purpose,
                    id_other_service: id_other_service,
                    id_consent: id_consent,
                    contact_typein: contact_typein,
                    contact_check: contact_check,
                    spreadsheet_location: spreadsheet_location,
                    employee_initials: employee_initials,
                    spreadsheet_notes: spreadsheet_notes,
                    pls_favorites: pls_favorites,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {
                    $('#submit-lab-spreadsheet').attr('disabled',false);
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $('#patient-lab-spreadsheet-message').html('').html(data.message);

                    if(data.success==true){
                        $('body').find('#patientLabSpreadsheetModal').modal('toggle');
                    }
                }
            });
            flag = true;
        }
    });

    $('body').on('click','.set_na_3day', function(e){
        var name = $(this).data('patientname');
        if (confirm('Are you sure you want to update "3 Day Follow Up" to "N/A" for '+name+'?')) {
            var id_consent = $(this).data('idconsent');
            var id_row = $(this).data('idrow');

            $.ajax({
                url: APP_URL+'setNa3day',
                method:'post',
                data:{
                    id_consent: id_consent,
                    id_row: id_row,
                    _token: $('[name="_token"]').val()
                },
            }).done(function(data){
                if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                    window.location = data.redirect;
                    return;
                }

                $('body').find('#next_tab_day3_follow_up_'+id_consent+'_'+id_row).text('N/A');

                $('#server-message').html('').html(data.message);
                setTimeout( function (){
                    $('#server-message').html('');
                },3000);

            });
        }
    });

    var oriVal;
    var idpls;
    var id_consent;
    var id_row;
    $("#table-speadsheet").on('dblclick', '.input-edit-wrap', function () {
        oriVal = $(this).text();
        id_consent = $(this).data('idconsent');
        id_row = $(this).data('idrow');
        $(this).text("");
        $("<input id='input-edit-wrap-"+id_consent+"-"+id_row+"' class='accept-tab pls_date_format' type='text' value='"+oriVal.trim()+"'>").datepicker({ dateFormat: 'dd/mm/yy'}).appendTo(this).focus();
        /*$("td").removeClass('rowSelected');
        $("tr.rowid_"+idpls+" > td").addClass('rowSelected');*/
    });
    $("#table-speadsheet").on('focusout', '.input-edit-wrap > input', function (event) {
        if($('.datepicker').length==1){
            return;
        }
        var $this = $(this);
        setTimeout(
            function() {
                if(flag==true){
                    flag = false;
                    $.ajax({
                        type: "POST",
                        url: APP_URL+'savePlsDate',
                        data: { 
                            id_consent: id_consent,
                            id_row: id_row,
                            result_date: ($this.val() == oriVal) ? oriVal : $this.val(),
                            input: 'result_date',
                            _token: $('[name="_token"]').val()
                        },
                        success: function(data)
                        {    
                            $('#input-edit-wrap-'+id_consent+'-'+id_row).datepicker("hide");
                            if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                                window.location = data.redirect;
                                return;
                            }

                            $this.parent().text(($this.val() == oriVal) ? oriVal : $this.val());
                            $this.remove();
                            $('#server-message').html('').html(data.message);
                            setTimeout( function (){
                                $('#server-message').html('');
                            },3000);


                            if(event.keyCode==9){
                                setTimeout( function (){
                                    $('#next_tab_day3_follow_up_'+id_consent+'_'+id_row).dblclick();
                                },500);
                            }
                        }
                    });
                    flag = true;
                }
            }, 1000
        );
    });

    /*$(document).on('focusout','.accept-tab',function(event){
        if($('.datepicker').length==1){
            return;
        }
        //if(event.keyCode==9){
            console.log(event.keyCode);
            var next = $(this).parent().next();
            if(next.hasClass('pas-tab')){
                next.next().dblclick();
            }else{
                next.dblclick();
            }
        //}
    })*/

    /*results_sent_to_patient_date*/
    $("#table-speadsheet").on('dblclick', '.textarea-edit-wrap', function () {
        oriVal = $(this).text();
        id_consent = $(this).data('idconsent');
        id_row = $(this).data('idrow');
        $(this).text("");
        $("<textarea class='accept-tab' style='position:absolute;top:0;z-index:100;width:95%;'>"+$.trim(oriVal)+"</textarea>").appendTo(this).focus();
        /*$("td").removeClass('rowSelected');
        $("tr.rowid_"+idpls+" > td").addClass('rowSelected');*/
    });
    $("#table-speadsheet").on('focusout', '.textarea-edit-wrap > textarea', function (event) {
        var $this = $(this);
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'savePlsDate',
                data: { 
                    id_consent: id_consent,
                    id_row: id_row,
                    result_date: ($this.val() == oriVal) ? oriVal : $this.val(),
                    input: 'results_sent_to_patient_date',
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $this.parent().text(($this.val() == oriVal) ? oriVal : $this.val());
                    $this.remove(); 
                    $('#server-message').html('').html(data.message);
                    setTimeout( function (){
                        $('#server-message').html('');
                    },3000);
                    
                    if(event.keyCode==9){
                        setTimeout( function (){
                            $('#next_tab_row_note_'+id_consent+'_'+id_row).dblclick();
                        },500);
                    }
                }
            });
            flag = true;
        }
    });

    $("#table-speadsheet").on('dblclick', '.notes-edit-wrap', function () {
        oriVal = $(this).text();
        id_consent = $(this).data('idconsent');
        id_row = $(this).data('idrow');
        $(this).text("");
        $("<textarea class='accept-tab' style='position:absolute;top:0;z-index:100;width:95%;'>"+$.trim(oriVal)+"</textarea>").appendTo(this).focus();
        $('#table-scroll').scrollLeft(-500);
        /*$("td").removeClass('rowSelected');
        $("tr.rowid_"+idpls+" > td").addClass('rowSelected');*/
    });
    $("#table-speadsheet").on('focusout', '.notes-edit-wrap > textarea', function () {
        var $this = $(this);
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'savePlsDate',
                data: { 
                    id_consent: id_consent,
                    id_row: id_row,
                    input: 'notes',
                    result_date: ($this.val() == oriVal) ? oriVal : $this.val(),
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $this.parent().text(($this.val() == oriVal) ? oriVal : $this.val());
                    $this.remove();
                    $('#server-message').html('').html(data.message);
                    setTimeout( function (){
                        $('#server-message').html('');
                    },3000);
                    //$("tr.rowid_"+idpls+" > td").removeClass('rowSelected');
                }
            });
            flag = true;
        }
    });

    $("#table-speadsheet").on('dblclick', '.follow_up-edit-wrap', function () {
        oriVal = $(this).text();
        id_consent = $(this).data('idconsent');
        id_row = $(this).data('idrow');
        $(this).text("");
        $("<textarea class='accept-tab' style='position:absolute;top:0;z-index:100;width:95%;'>"+$.trim(oriVal)+"</textarea>").appendTo(this).focus();
        /*$("td").removeClass('rowSelected');
        $("tr.rowid_"+idpls+" > td").addClass('rowSelected');*/
    });
    $("#table-speadsheet").on('focusout', '.follow_up-edit-wrap > textarea', function (event) {
        var $this = $(this);
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'savePlsDate',
                data: {
                    id_consent: id_consent,
                    id_row: id_row,
                    result_date: ($this.val() == oriVal) ? oriVal : $this.val(),
                    input: 'follow_up',
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $this.parent().text(($this.val() == oriVal) ? oriVal : $this.val());
                    $this.remove();
                    $('#server-message').html('').html(data.message);
                    setTimeout( function (){
                        $('#server-message').html('');
                    },3000);
                    
                    if(event.keyCode==9){
                        setTimeout( function (){
                            $('#next_tab_results_sent_to_patient_date_'+id_consent+'_'+id_row).dblclick();
                        },500);
                    }
                }
            });
            flag = true;
        }
    });

    $("#table-speadsheet").on('dblclick', '.modal-notes-edit-wrap', function () {
        oriVal = $(this).text();
        idpls = $(this).data('idpls');
        $(this).text("");
        $("<textarea class='accept-tab' style='position:absolute;top:0;z-index:100;width:95%;'>"+$.trim(oriVal)+"</textarea>").appendTo(this).focus();
        $('#table-scroll').scrollLeft(-500);
    });
    $("#table-speadsheet").on('focusout', '.modal-notes-edit-wrap > textarea', function () {
        var $this = $(this);
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'savePlsDate',
                data: { 
                    idpls: idpls,
                    input: 'modal_notes',
                    result_date: ($this.val() == oriVal) ? oriVal : $this.val(),
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    $this.parent().text(($this.val() == oriVal) ? oriVal : $this.val());
                    $this.remove();
                    $('#server-message').html('').html(data.message);
                    setTimeout( function (){
                        $('#server-message').html('');
                    },3000);
                }
            });
            flag = true;
        }
    });

    $('.tbskinPin-submit').click(function(){
        if(flag==true){
            flag = false;

            var pin = $('#tbskin_pin').val();
            var tbskinButtonId = localStorage.getItem('tbskinButtonId');
            $.ajax({
                type: "POST",
                url: APP_URL+'tbskinPin',
                data: {
                    pin: pin,
                    tbskinButtonId: tbskinButtonId,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if(data.success==true) {
                        $('body').find('#tbskinPin').modal('toggle');
                        $('#tbskin_pin').val('');
                        $('body').find('#'+tbskinButtonId).click();
                    }else {
                        alert('Pin Code was incorect!')
                    }


                }
            });
            flag = true;
        }
    });

    $('#table-speadsheet .counter').click(function(){
        if(flag==true){
            flag = false;

            id_consent = $(this).data('idconsent');
            id_row = $(this).data('idrow');

            if($("tr.rowid_"+id_consent+"_"+id_row+" > td").hasClass('rowSelected')){
                var hightlight = 0;
            }else{
                var hightlight = 1;
            }
            $.ajax({
                type: "POST",
                url: APP_URL+'savePlsDate',
                data: {
                    id_consent: id_consent,
                    id_row: id_row,
                    result_date: hightlight,
                    input: 'hightlight',
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        window.location = data.redirect;
                        return;
                    }

                    if(data.success==true){
                        if($("tr.rowid_"+id_consent+"_"+id_row+" > td").hasClass('rowSelected')){
                            $("tr.rowid_"+id_consent+"_"+id_row+" > td").removeClass('rowSelected');
                        }else{
                            $("tr.rowid_"+id_consent+"_"+id_row+" > td").removeClass('rowSelected');
                            $("tr.rowid_"+id_consent+"_"+id_row+" > td").addClass('rowSelected');
                        }

                        $('#server-message').html('').html(data.message);
                        setTimeout( function (){
                            $('#server-message').html('');
                        },3000);
                    }
                }
            });
            flag = true;
        }
    });

    /*const tableSpreadsheet = document.getElementById('table-speadsheet');
    if(tableSpreadsheet != null){
        document.addEventListener("keydown", function(event) {
            if(event.keyCode==9){
                var prevFocus;
                //var nexttab = $(this).data('nexttab');
                 $(".accept-tab").focus(function() {
                    if (typeof prevFocus  !== "undefined") {
                        var nexttab = $(this).data('nexttab');
                        console.log(nexttab);
                    }
                    prevFocus = $(this);

                });
            }
        });
    }*/

    /*PLS File Upload*/

    /*END PLS File Upload*/
    /*End Patient Lab Spreadsheet*/

    /*TB Skin Report*/
    var oriVal;
    var id;
    var input;
    $("#table-speadsheet").on('dblclick', '.company-edit-wrap,.staff_initials-edit-wrap,.notes_tbskin-edit-wrap,.email_sent-edit-wrap', function () {
        oriVal = $(this).text();
        id = $(this).data('id');
        input = $(this).data('input');
        $(this).text("");
        $("<textarea class='accept-tab' style='z-index:100;width:95%;'>"+$.trim(oriVal)+"</textarea>").appendTo(this).focus();
        /*$("td").removeClass('rowSelected');
        $("tr.rowid_"+idpls+" > td").addClass('rowSelected');*/
    });
    $("#table-speadsheet").on('focusout', '.company-edit-wrap > textarea, .staff_initials-edit-wrap > textarea, .notes_tbskin-edit-wrap > textarea, .email_sent-edit-wrap > textarea', function (event) {
        var $this = $(this);
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'saveTbSkinReport',
                data: { 
                    id: id,
                    text: ($this.val() == oriVal) ? oriVal : $this.val(),
                    input: input,
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {
                    if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                        location.reload();
                        return;
                    }

                    $this.parent().text(($this.val() == oriVal) ? oriVal : $this.val());
                    $this.remove();
                    $('#server-message').html('').html(data.message);
                    setTimeout( function (){
                        $('#server-message').html('');
                    },3000);
                }
            });
            flag = true;
        }
    });

    $("#table-speadsheet").on('click', '.tbskin_archive', function (event) {
        if (confirm('Are you sure you want to archive this Tb Skin form?')) {
            if(flag==true){
                var $this = $(this);
                flag = false;
                $.ajax({
                    type: "POST",
                    url: APP_URL+'tbSkinArchive',
                    data: { 
                        id: $(this).data('id'),
                        _token: $('[name="_token"]').val()
                    },
                    success: function(data)
                    {    
                        if(typeof(data.token_mismatch) != "undefined" && data.token_mismatch){
                            window.location = data.redirect;
                            return;
                        }
                        $this.html('Archived').attr('disabled',true);
                        $('#server-message').html('').html(data.message);
                        setTimeout( function (){
                            $('#server-message').html('');
                        },3000);
                    }
                });
                flag = true;
            }
        }
    });
    /*End TB Skin Report*/

    /*Vaccine Edit Safety Request*/
    $('body').on('change', '#comparation_sign_low', function() {
        var val = $( "#comparation_sign_low" ).val();
        if(val == '') {
            $('#administered_age_low').val('');
            $('#date_type_low').find('option:eq(0)').prop('selected', true);
        }
    });

    $('body').on('change', '#comparation_sign_high', function() {
        var val = $( "#comparation_sign_high" ).val();
        if(val == '') {
            $('#administered_age_high').val('');
            $('#date_type_high').find('option:eq(0)').prop('selected', true);
        }
    });
    /*END Vaccine Edit Safety Request*/

    /* Management pages search */
    $('body').on('keyup','#managementSearch', function(e) {
        var input, filter, ul, li, a, i;
        input = document.getElementById("managementSearch");
        filter = input.value.toUpperCase();
        ul = document.getElementById("ul");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            if (a.innerHTML.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";

            }
        }
    });
    /* END Management pages search */


    /* CPL Stuff */
    $('#send-to-cpl').click(function(){
        if(flag==true){
            flag = false;
            $.ajax({
                type: "POST",
                url: APP_URL+'sendToCpl',
                data: {
                    _token: $('[name="_token"]').val()
                },
                success: function(data)
                {   
                    console.log(data);
                }
            });
            flag = true;
        }
    });

    $('body').on('change', '.cpl_item_custom_panel', function () {
        var selectValue = $(this).val();
        var id_lab_item = $(this).data('itemid');
        var id_cpl_lab_item_details = $(this).data('id_cpl_lab_item_details');

        if (flag == true) {
            flag = false;

            $.ajax({
                type: "POST",
                url: APP_URL + 'CPL/updatePanelItemStatus',
                data: {
                    id: id_cpl_lab_item_details,
                    id_lab_item: id_lab_item,
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    $('#cpl_item_custom_panel_' + id_lab_item).attr('data-id_cpl_lab_item_details', data.id_cpl_lab_item_details);
                    $('#add_item_to_panel_' + id_lab_item).attr('data-idcpllabitemdetails', data.id_cpl_lab_item_details)

                   /*  $('.add_item_name_').addClass('add_item_name_' + data.id_cpl_lab_item_details).removeClass('add_item_name_');
                    $('.add_code_').addClass('add_code_' + data.id_cpl_lab_item_details).removeClass('add_code_');
                    $('.add_container_type_').addClass('add_container_type_' + data.id_cpl_lab_item_details).removeClass('add_container_type_');
                    $('.add_temperature_').addClass('add_temperature_' + data.id_cpl_lab_item_details).removeClass('add_temperature_');
 */
                    if (selectValue == 1) {
                        $('#tableRowPanelItems_' + id_lab_item).show()
                        $('.main_item_' + id_lab_item).hide()
                    } else {
                        $('#tableRowPanelItems_' + id_lab_item).hide()
                        $('.main_item_' + id_lab_item).show()
                    }
                }
            });
            flag = true;
        }
    });

    $('body').on('click', '.remove_item_from_panel', function (e) {
        if (confirm('Are you sure you want to delete this?')) {
            if (flag == true) {
                flag = false;
                var panelItemId = $(this).data('panelitemid'); 
                $.ajax({
                    type: "POST",
                    url: APP_URL + 'CPL/deletePanelItem',
                    data: {
                        id: panelItemId,
                        _token: $('[name="_token"]').val()
                    },
                    success: function (data) {
                        if(data.success==1) {
                            $('#tr_panel_id_'+panelItemId).remove()
                        }
                    }
                });
                flag = true;
            }
        }
    });

    $('.add_item_to_panel').click(function () {
        var idcpllabitemdetails = $(this).data('idcpllabitemdetails');
        var itemid = $(this).data('itemid');
        
        if ($('#table_items_panel_' + itemid + ' tr').length>=20) {
            alert("There are too many tests being sent on this requisition. The limit is 20 tests per requisition.")
            return false;
        }

        var add_item_name = $('body').find('#add_item_name_' + itemid).val()
        var add_code = $('body').find('#add_code_' + itemid).val()
        var add_container_type = $('body').find('#add_container_type_' + itemid).val()
        var add_temperature = $('body').find('#add_temperature_' + itemid).val()

        if (flag == true) {
            flag = false;
            var panelItemId = $(this).data('panelitemid');
            $.ajax({
                type: "POST",
                url: APP_URL + 'CPL/addPanelItem',
                data: {
                    itemid: itemid,
                    idcpllabitemdetails: idcpllabitemdetails,
                    add_item_name: add_item_name,
                    add_code: add_code,
                    add_container_type: add_container_type,
                    add_temperature: add_temperature,
                    _token: $('[name="_token"]').val()
                },
                success: function (data) {
                    if(data.success==true) {
                        $('#table_items_panel_'+itemid).html('').html(data.html);
                        $('#add_item_name_' + itemid).val('')
                        $('#add_code_' + itemid).val('')
                        $('#add_container_type_' + itemid).val('')
                        $('#add_temperature_' + itemid).val('')
                    } else {
                        $('#panel_message_' + itemid).html('').html(data.html);
                    }
                }
            });
            flag = true;
        }
    });


    var href = '';
    $('body').on('click', '.preSubmitFormOpenPDF', function (e) {
        href = $(this).data('href')
        $('body').find('.formsSaveButton').click()
    })

    /* END CPL Stuff*/

    window.onerror = function(msg, url, line)
    {
        $.ajax({
            type: "POST",
            url: APP_URL+'logJsErrors',
            data: {
                msg:  msg,
                url: url,
                line: line,
                _token: $('[name="_token"]').val()
            },
            success: function(data)
            {   
                return;
            }
        });

    };

    $('.show_vis_form_upload').click(function () {
        $('#vis_form').click();
    });

    $("#vis_form").change(function () {
        var fileName = $(this).val();
        $(".vis_name").html(fileName);
    });

    //AM/PM shit
    $('.auto_ampm').change(function() {
        var hour = $(this).val()
        const incl_am = ['08','09','10','11']
        const incl_pm = ['01','02','03','04','05','12']
        
        if(incl_am.includes(hour)) {
            $('#appt_period option:eq(1)').prop('selected', true)
        } else if(incl_pm.includes(hour)) {
            $('#appt_period option:eq(2)').prop('selected', true)
        } else {
            $('#appt_period option:eq(0)').prop('selected', true)
        }
    })
    ////////////
    $('body').on('change','#canned_note', function () {
        var val = $('#canned_note').val()
        $('#vpnm_note').val(val)
    })

    var hasI3LocationOptions = false
    $('body').on('change','.i3_company_select', function () {
        var company_id = $(this).val();
        $.ajax({
            type: "POST",
            url: APP_URL + 'getCompanyI3Locations',
            data: {
                company_id: company_id,
                _token: $('[name="_token"]').val()
            },
            success: function (data) {
                var $dropdown = $(".i3_company_location_select");
                $dropdown.find('option').not(':first').remove();
                if(data.count==0) {
                    //$('#useE7I3LocationId').show()
                    //$('#wrap_i3_company_location_select').hide()
                    //$dropdown.find('option').not(':first').remove();
                    hasI3LocationOptions = false
                } else {
                   // $('#useE7I3LocationId').hide()
                    //$('#wrap_i3_company_location_select').show()

                    $.each(data.i3Locations, function() {
                        $dropdown.append($("<option />").val(this.i3_location_id).text(this.location_name+' - '+this.i3_location_id));
                    });
                    hasI3LocationOptions = true
                }
            }
        });
    })

    $('body').on('change','.i3_company_location_select', function () {
        $('#use_e7health_i3_id').prop('checked', false);
    })

    $('body').on('change','#use_e7health_i3_id', function () {
        $(".i3_company_location_select option:first").attr('selected','selected');
    })

    $('body').on('click','#pushOrderToI3',function(e){ 
        var i3_company_select = $('.i3_company_select').val()
        var i3_company_location_select = $('.i3_company_location_select').val()
        var reason_for_test = $('#reason_for_test').val()
        var order_id = $(this).data('orderid')
        var plsid = $(this).data('plsid')
        var id_user = $(this).data('userid')
        var id_other_service = $(this).data('idotherservice')
        var i3_package = $(this).data('i3package')
        var use_e7health_i3_id = false

        var isdot = $(this).data('isdot')
        var ssn = $('.ssn').val()
        var dot_agency = $('.dot_agency').val()
        var cdl_number = $('.cdl_number').val()
        var dot_state = $('.dot_state').val()
        
        if($('#use_e7health_i3_id').is(":checked")) {
            use_e7health_i3_id = true
        }

        if(i3_company_select == '') {
            alert('Select company.')
            return;
        }

        if(i3_company_location_select == '' && use_e7health_i3_id==false && hasI3LocationOptions == true) {
            alert('Select company location.')
            return;
        }

        if(isdot=='yes') {
            if((cdl_number == '' || dot_state == '') && dot_agency=='FMCSA' && dot_agency!='') {
                alert('Complete DOT section.')
                return;
            } else if(ssn == '' && dot_agency != 'FMCSA' && dot_agency!='') {
                alert('Complete DOT section.')
                return;
            }

            if(dot_agency == '') {
                alert('Complete DOT section.')
                return;
            }
    
        }

        // if(i3_company_location_select == '' && use_e7health_i3_id==false && hasI3LocationOptions == false) {
        //     alert('Check e7Health location id use confirmation checkbox')
        //     return;
        // }

        $('#pushOrderToI3').prop( "disabled", true );
        $.ajax({
            type: "POST",
            url: APP_URL + 'pushOrderToI3',
            data: {
                i3_company_select: i3_company_select,
                i3_company_location_id: i3_company_location_select,
                order_id: order_id,
                id_user: id_user,
                id_other_service: id_other_service,
                i3_package: i3_package,
                plsid:plsid,
                reason: reason_for_test,
                use_e7health_i3_id: use_e7health_i3_id,
                isdot: isdot,
                ssn: ssn,
                dot_agency: dot_agency,
                cdl_number: cdl_number,
                dot_state: dot_state,
                _token: $('[name="_token"]').val()
            },
            success: function (data) {
                $('#wrap_pushOrderToI3').hide()
                $('#pushOrderToI3-message').html('').html(data.message);
                if(data.success==true) {

                } else if(data.success==false && data.i3_location_id==false) {
                    $('#wrap_addI3LocationIdToCompany').show()
                    $('#save_addI3LocationIdToCompany').html(data.button_text)
                    $("#save_addI3LocationIdToCompany").attr( "data-companyid", data.company_id );
                } else {
                    $(".i3_company_select option:first").attr('selected','selected');
                    $('#wrap_pushOrderToI3').show()
                }
                $('#pushOrderToI3').prop( "disabled", false );
            }
        });

    }) 

    $('body').on('change','.info_active_select',function(e){ 
        var id_user = $(this).data('userid')
        var info_active = $(this).val()

        $.ajax({
            type: "POST",
            url: APP_URL + 'update-info-active',
            data: {
                id_user: id_user,
                info_active: info_active,
                _token: $('[name="_token"]').val()
            },
            success: function (data) {
                location.reload();
            }
        });

    })

    $('body').on('click','#cancel_addI3LocationIdToCompany',function(){ 
        $('#wrap_addI3LocationIdToCompany').hide()
        $('#wrap_pushOrderToI3').show()
        $('#pushOrderToI3-message').html('')
    })

    $('body').on('click','#save_addI3LocationIdToCompany',function(e){ 
        var i3_location_id = $('#addI3LocationIdToCompany').val()
        var i3_location_name = $('#addI3LocationNameToCompany').val()
        var company_id = $(this).data('companyid')

        if(i3_location_id == '') {
            alert('i3 location id must not be empty')
            return;
        }

        if(i3_location_name == '') {
            alert('i3 location name must not be empty')
            return;
        }

        $.ajax({
            type: "POST",
            url: APP_URL + 'addI3LocationIdToCompany',
            data: {
                i3_location_id: i3_location_id,
                i3_location_name: i3_location_name,
                company_id: company_id,
                _token: $('[name="_token"]').val()
            },
            success: function (data) {
                $('#wrap_pushOrderToI3').show()
                $('#pushOrderToI3-message').html('').html(data.message);
                setTimeout( function (){
                    $('#pushOrderToI3-message').html('');
                }
                , 7500);
            }
        });
    })

    $('body').on('click','.addi3LocationRow',function(e){ 
        var a = $('#copy_i3LocationRow').html();
        $('.wrap_i3LocationRow').append(a);
    })

    $('body').on('click','.removei3LocationRow',function(e){ 
        $(this).closest(".i3LocationRow").remove();
    })

    $('body').on('click','.addSchoolServiceRow',function(e){ 
        var a = $('#copy_schoolServiceRow').html();
        $('.wrap_schoolServiceRow').append(a);
    })

    $('body').on('click','.removeSchoolServiceRow',function(e){ 
        $(this).closest(".schoolServiceRow").remove();
    })

    $('body').on('click','.addCompanyServiceRow',function(e){ 
        var a = $('#copy_companyServiceRow').html();
        let r = (Math.random() + 1).toString(36).substring(7);
        var aObj = $(a);

        aObj.find('#input_').attr('id', 'input_' + r);
        aObj.find('#list_').attr('id', 'list_' + r);

        a = aObj.prop('outerHTML');

        $('.wrap_companyServiceRow').append(a);
    })

    $('body').on('click','.removeCompanyServiceRow',function(e){ 
        $(this).closest(".companyServiceRow").remove();
    })

    $('body').on('change', '.school_attending', function (event) {
        event.stopPropagation();
        var val = $(this).val()
        if(val=='20') {
            $('.school_attending_other').show()
        } else {
            $('.school_attending_other').hide()
        }
    });

    $( document ).ready(function() {
        var reportVaccinePage = $('#report-vaccines-for-travel');
        var reportMasterPin = $('#report-master-pin');

        if (reportVaccinePage.length || reportMasterPin.length) {
            var date = new Date();
        
            let type = $('#date_options option:selected').val();
            switch (type) {
                case 'today':
                    var firstDay = new Date();
                    var lastDay = new Date();
                    $('#date-picker').daterangepicker({ startDate: firstDay, endDate: lastDay });
                    break;
                case 'week':
                    var firstDay = new Date(getFirstDayOfWeek());
                    var lastDay = new Date();
                    $('#date-picker').daterangepicker({ startDate: firstDay, endDate: lastDay });
                    break;
                case 'month':
                    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                    var lastDay = new Date();
                    $('#date-picker').daterangepicker({ startDate: firstDay, endDate: lastDay });
                    break;
                case 'year':
                    var firstDay = new Date(new Date().getFullYear(), 0, 1);
                    var lastDay = new Date();
                    $('#date-picker').daterangepicker({ startDate: firstDay, endDate: lastDay });
                    break;
                default:
                    var firstDay = new Date($("#start_date").val());
                    var lastDay =  new Date($("#end_date").val());
                    $('#date-picker').daterangepicker({ startDate: firstDay, endDate: lastDay });
            }
            populateDates(firstDay, lastDay);

            $('body').on('click','#date_options',function(e){ 
                var end = this.value;
                switch (end) {
                    case 'today':
                        var firstDay = new Date();
                        var lastDay = new Date();
                        $('#date-picker').daterangepicker({ startDate: firstDay, endDate: lastDay });
                        break;
                    case 'week':
                        var firstDay = new Date(getFirstDayOfWeek());
                        var lastDay = new Date();
                        $('#date-picker').daterangepicker({ startDate: firstDay, endDate: lastDay });
                        break;
                    case 'month':
                        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                        var lastDay = new Date();
                        $('#date-picker').daterangepicker({ startDate: firstDay, endDate: lastDay });
                        break;
                    case 'year':
                        var firstDay = new Date(new Date().getFullYear(), 0, 1);
                        var lastDay = new Date();
                        $('#date-picker').daterangepicker({ startDate: firstDay, endDate: lastDay });
                        break;
                    default:
                        $('#date-picker').val('');
                 }
                
                if (end != 'custom') {
                    populateDates(firstDay, lastDay);
                }
            })
    
            $(document).on("click",".applyBtn",function() {
                $("#date_options").val('custom');
                var firstDay = $('#date-picker').data('daterangepicker').startDate;
                var lastDay = $('#date-picker').data('daterangepicker').endDate;
                populateDates(firstDay, lastDay);
            });
    
            function getFirstDayOfWeek() {
                const date = new Date();
                const day = date.getDay();
                const diff = date.getDate() - day+1;
              
                return new Date(date.setDate(diff));
            }
    
            function populateDates(start, end) {
                start = new Date(start);
                end = new Date(end);
    
                const offsetStart = start.getTimezoneOffset();
                start = new Date(start.getTime() - (offsetStart*60*1000));
                start = start.toISOString().split('T')[0];
    
                const offsetEnd = end.getTimezoneOffset();
                end = new Date(end.getTime() - (offsetEnd*60*1000));
                end = end.toISOString().split('T')[0];
    
                $('#start_date').val(start);
                $('#end_date').val(end);
            }
        }
    });
    
})(window, document, jQuery);
